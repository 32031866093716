import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomSnackbarService } from './custom-snackbar.service';
import { Subscription } from 'rxjs';


import { trigger, transition, animate, style } from '@angular/animations';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
  animations: [
    trigger('state', [
      transition(':enter', [
        style({ top: '-80px' }),
        animate('1500ms', style({
          opacity: 1,
          top: '80px'
        })),
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.4, 0.0, 1, 1)', style({
          transform: 'translate(-50%, 0%) scale(0.3)',
          opacity: 0,
          top: '-100px'
        }))
      ])
    ])
  ]
})

export class CustomSnackbarComponent implements OnInit, OnDestroy {

   show = false;
   message: string = 'This is snackbar';
   type: string = 'success';
  private snackbarSubscription!: Subscription;

  constructor(private snackbarService: CustomSnackbarService) { }

  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbarState
    .subscribe(
      (state) => {
        if (state.type) {
          this.type = state.type;
        } else {
          this.type = 'success';
        }
        this.message = state.message;
        this.show = state.show;
        setTimeout(() => {
          this.show = false;
        }, 3000);
      });
  }

  ngOnDestroy() {
    this.snackbarSubscription.unsubscribe();
  }

}
