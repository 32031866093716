import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { AlertService } from './alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  animations: [
    trigger('slideFromRight', [
      transition(':enter', [
        style({ right: '-50%' }),
        animate(600, style({
          opacity: 1,
          right: '2rem'
        })),
      ]),
      transition(':leave', [
        style({ right: '2rem' }),
        animate(600, style({
          opacity: 0,
          right: '-50%'
        })),
      ])
    ]),
  ]
})
export class AlertComponent implements OnInit, OnDestroy {
  show = false;
  message: string = 'This is a alert box';
  type: string = 'success';
  heading: string;

  private alertSubscription!: Subscription;

  constructor(private alertService: AlertService) { }

  ngOnInit(): void {
    this.alertSubscription = this.alertService.alertState.subscribe({
      next: (state) => {
        if (state.heading) {
          this.heading = state.heading;
        } else if (state.type) {
          this.type = state.type;
        } else {
          this.type = 'success';
        }
        this.heading = state.heading;
        this.message = state.message;
        this.show = state.show;
        // it will disapear after 3 seconds.
        setTimeout(() => this.show = false, 3000);
      }
    })
  }

  ngOnDestroy() { this.alertSubscription.unsubscribe(); }
}
