
<section class="vh-100">
    <div class="container-fluid overflow-hidden">
        <div class="row">

            <div class="col-sm-12 col-md-7 col-lg-6 col-xl-6 px-0 d-none d-sm-block position-relative">
                <img src="../../../../assets/images/Group 1836.png" alt="Login image" class="w-100 vh-100 position-relative">
                <img src="../../../../assets/images/wize-logo.png" alt="Login image" class="logo">
                <p class="mb-0 warehouse">End to End Warehouse</p>
                <p class="mb-0 warehouse1">and Logistics.</p>
                <p class="mb-0 warehouse2">We guarantee your products from your</p>
                <p class="mb-0 warehouse3">supplier to your customer in record time</p>
                <p class="mb-0 warehouse4">with a 100% accuracy.</p>
                <p class="mb-0 rights">© 2022 Shtiks All Rights Reserved</p>
            </div>

            <div class="col-sm-12 col-md-5 col-lg-6 col-xl-6 text-black z-index-9">

                <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-5 mt-0 pt-0 login-wrapper">

                    <form class="mb-5">

                        <div *ngIf="reset_password_view" class="mb-200">

                            <div>
                                <h3 class="fw-bold mb-2 pb-2 f-s-36">Reset Password?</h3>
                                <h6 class="fw-normal mb-1 pb-1 f-s-18 welcome">How do you want to receive the code</h6>
                                <h6 class="fw-normal mb-4 pb-4 f-s-18 welcome">to reset your password?</h6>
                            </div>

                            <mat-radio-group name="reset_password" [(ngModel)]="reset_password">
                                <div class="mt-4 d-block">
                                    <mat-radio-button (change)="radiochange($event)" name="email_reset" value="email_reset">Send code via Email</mat-radio-button>
                                </div>
                                <div class="mt-3">
                                    <mat-radio-button (change)="radiochange($event)" name="phone_no_reset" value="phone_no_reset">Send code via Phone number</mat-radio-button>
                                </div>


                            </mat-radio-group>

                            <button *ngIf="reset_password === 'email_reset'" class="btn btn-info btn-lg btn-block w-100 custom-btn mt-3" (click)="changeview()" type="button">Submit</button>
                        </div>

                        <div *ngIf="reset_password_view1" class="mb-120">

                            <div>
                                <h3 class="fw-bold mb-2 pb-2 f-s-36">Reset Password?</h3>
                                <h6 class="fw-normal mb-1 pb-1 f-s-18 welcome">Enter the email associated with your account</h6>
                                <h6 class="fw-normal mb-1 pb-1 f-s-18 welcome">and we'll send an email with instructions to</h6>
                                <h6 class="fw-normal mb-4 pb-4 f-s-18 welcome">reset your password</h6>
                            </div>

                            <div class="form-outline mb-4">
                                <label class="form-label d-block f-s-16" for="form2Example18">E-mail address</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <!-- <mat-label>E-mail address</mat-label> -->
                                    <input matInput [(ngModel)]="email" name="email" placeholder="E-mail address">
                                </mat-form-field>

                            </div>

                            <button class="btn btn-info btn-lg btn-block w-100 custom-btn mt-3" (click)="forgotpassword()" type="button">Submit</button>
                        </div>

                    </form>

                </div>

            </div>

        </div>
    </div>
</section>
