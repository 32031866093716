import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  notes: string;
  detailmode: boolean;
}
@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  notes = '';
  constructor(public dialogRef: MatDialogRef<NotesComponent>,@Inject(MAT_DIALOG_DATA) public data: DialogData,) {
    this.notes = this.data.notes || '';
   }

  sendnotes(){
    // this.notes === undefined || this.notes === null || this.notes === '' || this.notes === '' ? '' : this.notes;
    this.notes = this.notes || '';
    this.doaction();
  }

  doaction() {
    this.dialogRef.close(this.notes);
  }

  ngOnInit(): void {
  }

}
