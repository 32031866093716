<div *ngIf="show" class="alert-box" [ngClass]="type == 'success' ? 'success' : type" @slideFromRight>
   <ng-container [ngSwitch]="type">
      <img *ngSwitchCase="'success'" src="../../../../assets/images/success.png">
      <img *ngSwitchCase="'error'" src="../../../../assets/images/error.png">
   </ng-container>
   <div class="msg-container d-flex">
      <div *ngIf="heading">
         <b class="heading"> {{ heading }}</b>
      </div>
      <span class="msg">{{ message }}</span>
   </div>
</div>