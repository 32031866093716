<div class="container-fluid filter-content filter-container">
  <div class="border-bottom">
    <div class="col-9 pl-3">
      <h4 mat-dialog-title>Columns</h4>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="mt-3">
      <div class="row" *ngFor="let col of master_columns">
        <div class="col-xl-2">
          <mat-checkbox
            *ngIf="!col?.allowtohide"
            class="gridSelectionChkbx"
            [disabled]="!col?.allowtohide"
            [checked]="true"
          >
          </mat-checkbox>

          <mat-checkbox
            *ngIf="col?.allowtohide"
            class="gridSelectionChkbx"
            [disabled]="!col?.allowtohide"
            [(ngModel)]="col.isVisible"
            (click)="$event.stopPropagation()"
            (change)="$event ? rowToogle($event, col) : null"
          >
          </mat-checkbox>
        </div>
        <div class="col-xl-10 col-xs-12">
          <p>{{ col.displayName }}</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <div class="d-flex justify-content-center align-items-center">
      <button mat-flat-button class="btn-outlined" (click)="clear()">
        Cancel
      </button>
      <button mat-flat-button class="btn-main" (click)="submit()">
        Update
      </button>
    </div>
  </mat-dialog-actions>
</div>
