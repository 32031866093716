

export class AppConstants {

  public static APP_ROUTES = {
    dashboard: {
      default: 'dashboard',
      dashboard: 'dashboard',
    },
    purchase: {
      default: 'purchase/orders',
      orders: 'purchase/orders',
      createOrder: 'purchase/orders/create-order'
    },
    outbound: {
      default: 'outbound/overview',
      inpicking:'outbound/in-picking',
      overview: 'outbound/overview',
      shipment:'outbound/shipment',
      overviewRule: 'outbound/overview/rule',
      overviewRuleCreateNew: 'outbound/overview/rule/create-new',
      createOrder: 'outbound/overview/create-order',
      editOrder: 'outbound/overview/edit-order/',
      createBatch: 'outbound/overview/create-batch',
      pickByOrder: 'outbound/pick-by-order',
      pickByBatch: 'outbound/pick-by-batch',
      pickByBatchRule: 'outbound/pick-by-batch/rule',
      pickTickets:'outbound/pick-tickets',
      releases:'outbound/releases',
      releaseDetail:'outbound/releases/release-detail',
      createRelease:'outbound/createreleases',
      pickByBatchRuleCreateNew: 'outbound/pick-by-batch/rule/create-new',
      packSmallParcel: 'outbound/pack-small-parcel',
      packFreight: 'outbound/pack-freight',
      packStation: 'outbound/pack-small-parcel/station-pack',
      packAndShipStation: 'outbound/pack-small-parcel/station-pack-and-ship',
      // consolidate: 'outbound/consolidate',
      // addConsolidate: 'outbound/consolidate/add-consolidate',
      shipFreight: 'outbound/ship/freight',
      shipSmallParcel: 'outbound/ship/small-parcel',
      shipStation: 'outbound/ship/small-parcel/station-pack',
      shipPackAndShipStation: 'outbound/ship/small-parcel/station-pack-and-ship',
      orderOverviewDetail: 'outbound/detail/order-overview-detail/',
      orderDetail: 'outbound/detail/order-detail/',

    },
    inbound: {
      default: 'inbound/overview',
      overview: 'inbound/overview',
      createAsn: 'inbound/overview/create-asn',
      asnDetail: 'inbound/overview/asn-detail/',
      createBlindReceipt: 'inbound/overview/create-blind-receipt',
      checkin: 'inbound/checkin',
      checkinNew: 'inbound/checkin/checkin-new',
      unload: 'inbound/unload',
      ansUnload: 'inbound/unload/ans-unload',
      receive: 'inbound/receive',
      receiver: 'inbound/receive/receiver',
      putaway: 'inbound/putaway',
      putawayNew: 'inbound/putaway/putaway-new',
      history: 'inbound/history',
    },
    inventory: {
      default: 'inventory/overview',
      overview: 'inventory/overview',
      stockTransfer: 'inventory/stock-transfer',
      createStockTransfer: 'inventory/stock-transfer/create-new',
      inventoryAdjustment: 'inventory/inventory-adjustment',
      createInventoryAdjustment: 'inventory/inventory-adjustment/create-new',
    },
    accountmanagement: {
      default: 'accountmanagement/companyaccount/companylist',
      subscriptionplan: 'accountmanagement/subscriptionplan',
      subscriptionoverview: 'accountmanagement/subscriptionplan/subscriptionoverview',
      integrationsetting: 'accountmanagement/integrationsetting',
      masterdata: 'accountmanagement/masterdata',
      lookupfield: 'accountmanagement/lookupfield',
      masterdatadetail:'accountmanagement/masterdata/masterdatadetail',
      createaccount:'accountmanagement/companyaccount/createaccount',
      companydetail:'accountmanagement/companyaccount/companyoverview/companydetail',
      companyoverview: 'accountmanagement/companyaccount/companyoverview',
      createSubscriptionPlan:'accountmanagement/subscriptionplan/createsubscriptionplan',
      updateSubscriptionPlan:'accountmanagement/subscriptionplan/updatesubscriptionplan',
      newclient:'accountmanagement/companyaccount/AMclients/newclient',
      ManagementUsers:'accountmanagement/companyaccount/AMusers',
      newuser: 'accountmanagement/companyaccount/AMusers/newuser',
      useroverview: 'accountmanagement/companyaccount/AMusers/useroview',
      useredit: 'accountmanagement/companyaccount/AMusers/useredit',
      clientoverview: 'accountmanagement/companyaccount/AMclients/clientoverview',
      clientdetails: 'accountmanagement/companyaccount/AMclients/clientoverview/clientdetails',
      Managementclients: 'accountmanagement/companyaccount/AMclients',
      ManagementSettings:'accountmanagement/companyaccount/AMsettinngs',
      AcccountManagementWarehouse:'accountmanagement/companyaccount/warehouse',
      AccountManagementAddWarehouse:'accountmanagement/companyaccount/warehouse/addwarehouse',
      updateWarehouse:'accountmanagement/companyaccount/warehouse/updatewarehouse',
      addlocation:'accountmanagement/companyaccount/addwarehouse/addlocation',
      updatelocation:'accountmanagement/companyaccount/addwarehouse/updatelocation',
      addWarehouse:'accountmanagement/companyaccount/companyoverview/addwarehouse',
      locationTypleList:'accountmanagement/companyaccount/addwarehouse/typelist',
      addAreaType:'accountmanagement/companyaccount/addwarehouse/addareatype',
      updateAreaType:'accountmanagement/companyaccount/addwarehouse/updateareatype',
      areaTypelist:'accountmanagement/companyaccount/addwarehouse/areatypelist',
      formatlist: 'accountmanagement/companyaccount/addwarehouse/formatlist',
      locationList:'accountmanagement/companyaccount/addwarehouse/locationlist',
      addFormat:'accountmanagement/companyaccount/addwarehouse/addformat',
      updateFormat:'accountmanagement/companyaccount/addwarehouse/updateformat',
      addtype:'accountmanagement/companyaccount/addwarehouse/addtype',

    },
    ordermanagement:{
        default:'ordermanagement/order-overview',
        Orderdetail: 'ordermanagement/order-overview/order-detail',
        failedOrderQueue: 'ordermanagement/failed-order-queue',

    },

    account: {
      default: 'account/warehouse',
      addWarehouse:'account/warehouse/addwarehouse',
      updateWarehouse:'account/warehouse/updatewarehouse',
      locationList:'account/warehouse/locationlist',
      addlocation:'account/warehouse/addlocation',
      updatelocation:'account/warehouse/updatelocation',
      addFormat:'account/warehouse/addformat',
      updateFormat:'account/warehouse/updateformat',
      formatlist:'account/warehouse/formatlist',
      addLocationType:'account/warehouse/addtype',
      locationTypleList:'account/warehouse/typelist',
      addAreaType:'account/warehouse/addareatype',
      updateAreaType:'account/warehouse/updateareatype',
      areaTypelist:'account/warehouse/areatypelist',
      clients: 'account/clients/list',
      clientoverview: 'account/clients/overview',
      clientdetails: 'account/clients/overview/clientdetails',
      clientSettings: 'account/clients/settings',
      newclient:'account/clients/newclient',
      suppliers: 'account/clients/suppliers',
      newsupplier: 'account/clients/suppliers/newsupplier',
      supplieroverview:'account/clients/suppliers/overview',
      supplieredit:'account/clients/suppliers/overview/updatesupplier',
      carrier: 'account/clients/shipmethods',
      newcarrier: 'account/clients/shipmethods/newshipmethod',
      carrieredit: 'account/clients/shipmethods/updateshipmethod',
      carrieroverview: 'account/clients/shipmethods/overview',
      customers:'account/clients/customers',
      newcustomer:'account/clients/customers/newcustomer',
      customeroverview:'account/clients/customers/customeroverview',
      itemmaster:'account/clients/itemmaster',
      newitem:'account/clients/itemmaster/newitem',
      users:'account/users',
      newusers:'account/users/newuser',
      useroverview:'account/users/useroview',
      useredit:'account/users/useredit',
      roles:'account/roles',
      roleslist: 'account/roles/newrole',
      seetings:'account/settings',
      shipmethod: 'account/shipmethod'
    },
    uikit: {
      default: 'ui-kit'
    },
    login: {
      default: 'login'
    },
    home: {
      default: 'home'
    },
    responsiveHome: {
      default: 'home-responsive',
      orderPicker: 'order-picker'
    },
    // -------- Mobile Menus
    pick: {
      default: 'mobile/outbound/scan-order',
      scanOrder: 'mobile/outbound/scan-order',
      scanBatch: 'mobile/outbound/scan-batch',
    },
    asn : {
      default: 'mobile/inbound/unload',
      unload: 'mobile/inbound/unload',
      receive: 'mobile/inbound/receive',
      putaway: 'mobile/inbound/putaway',
    }

  };
  public static APP_MENU_BUTTONS = [
    {
      name: 'dashboard',
      iconUrl: 'assets/icon/menu/dashboard.svg',
      tooltip: 'Dashboard',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    {
      name: 'purchase',
      iconUrl: 'assets/icon/menu/purchase.svg',
      tooltip: 'Purchase',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    {
      name: 'inbound',
      iconUrl: 'assets/icon/menu/Inbound.svg',
      tooltip: 'Inbound',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'outbound',
      iconUrl: 'assets/icon/menu/Outbound.svg',
      tooltip: 'Outbound',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'inventory',
      iconUrl: 'assets/icon/menu/Inventory.svg',
      tooltip: 'Inventory',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'accountmanagement',
      iconUrl: 'assets/icon/menu/Account-Management.svg',
      tooltip: 'Account Management',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'ordermanagement',
      iconUrl: 'assets/icon/menu/Order-Management.svg',
      tooltip: 'Order Management',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },
    {
      name: 'account',
      iconUrl: 'assets/icon/menu/account.svg',
      tooltip: 'Account',
      isWeb: true,
      hasPanel: true,
      isCap: false
    },

    {
      name: 'uikit',
      iconUrl: 'assets/icon/menu/uikit.svg',
      tooltip: 'ui kit',
      isWeb: true,
      hasPanel: false,
      isCap: false
    },
    /// Mobile Menus
    {
      name: 'asn',
      iconUrl: 'assets/icon/menu/Inbound.svg',
      tooltip: 'Asn',
      isWeb: false,
      hasPanel: true,
      isCap: true
    },
    {
      name: 'pick',
      iconUrl: 'assets/icon/menu/Outbound.svg',
      tooltip: 'Pick',
      isWeb: false,
      hasPanel: true,
      isCap: false
    },


  ];

  public static MENU_LINKS_DATA = {

    dashboard: {
      title: '',
      name: 'Dashboard',
      index: 0,
      links: [],
      hasPanel: false,
      isLinkExpanded: false,
    },
    purchase: {
      title: 'purchase',
      name: 'Purchase',
      index: 1,
      hasPanel: false,
      isLinkExpanded: false
    },
    inbound: {
      title: 'inbound',
      name: 'Inbound',
      defaultUrl: 'inbound',
      index: 2,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Overview | 721 ',
          route: AppConstants.APP_ROUTES.inbound.overview, // '/overview';
        },
        {
          name: 'Check in | 724 ',
          route: AppConstants.APP_ROUTES.inbound.checkin,
        },
        {
          name: 'Unload | 554 ',
          route: AppConstants.APP_ROUTES.inbound.unload,
        },
        {
          name: 'Receive | 324 ',
          route: AppConstants.APP_ROUTES.inbound.receive,
        },
        {
          name: 'Putaway | 314 ',
          route: AppConstants.APP_ROUTES.inbound.putaway,
        },
        {
          name: 'History',
          route: AppConstants.APP_ROUTES.inbound.history,
        }
      ]
    },
    outbound: {
      title: 'outbound',
      name: 'Outbound',
      index: 3,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.outbound.overview
        },
        {
          name: 'Shipments',
          route: AppConstants.APP_ROUTES.outbound.shipment
        },
        {
          name: 'In Picking',
          route: AppConstants.APP_ROUTES.outbound.releases,
          links: [
            // {
            //   name: 'orders ',
            //   route: AppConstants.APP_ROUTES.outbound.pickByOrder
            // },
            // {
            //   name: 'batches',
            //   route: AppConstants.APP_ROUTES.outbound.pickByBatch
            // },
            {
              name: 'Releases',
              route: AppConstants.APP_ROUTES.outbound.releases
            },
            {
              name: 'Pick Tickets',
              route: AppConstants.APP_ROUTES.outbound.pickTickets
            },
          ]
        },
        {
          name: 'In Packing',
          route: AppConstants.APP_ROUTES.outbound.packSmallParcel,
          links: [

            {
              name: 'Small-parcel  ',
              route: AppConstants.APP_ROUTES.outbound.packSmallParcel
            }
          ]
        },
        {
          name: 'In Shipping',
          route: AppConstants.APP_ROUTES.outbound.shipSmallParcel,
          links: [
            // {
            //   name: 'freight',
            //   route: AppConstants.APP_ROUTES.outbound.shipFreight
            // },
            {
              name: 'Small-parcel',
              route: AppConstants.APP_ROUTES.outbound.shipSmallParcel
            }
          ]
        },
        {
           name: 'Shipped',
         route: AppConstants.APP_ROUTES.inbound.history,
        }
      ]
    },
    inventory: {
      title: 'inventory',
      name: 'inventory',
      index: 4,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.inventory.default,
        },
        {
          name: 'Stock transfer',
          route: AppConstants.APP_ROUTES.inventory.stockTransfer,
        },
        {
          name: 'Inventory adjustment',
          route: AppConstants.APP_ROUTES.inventory.inventoryAdjustment,
        },
        // {
        //   name: 'inetory adjustment',
        //   route: AppConstants.APP_ROUTES.inbound.unload,
        // },
        // {
        //   name: 'cycle count',
        //   route: AppConstants.APP_ROUTES.inbound.receive,
        // },
        // {
        //   name: 'bill of material',
        //   route: AppConstants.APP_ROUTES.inbound.putaway,
        // }
      ]
    },
    accountmanagement: {
      title: 'Account Management',
      title2: 'Account',
      name: 'accountmanagement',
      index: 5,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Company Accounts',
          route: AppConstants.APP_ROUTES.accountmanagement.default
        },
        {
          name: 'Subscription Plan',
          route: AppConstants.APP_ROUTES.accountmanagement.subscriptionplan
        },
         {
          name: 'Integration Setting',
          route: AppConstants.APP_ROUTES.accountmanagement.integrationsetting
        },
        // {
        //   name: 'Master Data',
        //   route: AppConstants.APP_ROUTES.accountmanagement.masterdata
        // },
        {
          name: 'Master Data',
          route: AppConstants.APP_ROUTES.accountmanagement.masterdata
        },
        {
          name: 'Lookup Field',
          route: AppConstants.APP_ROUTES.accountmanagement.lookupfield
        }

      ],

      AccountLinks: [

        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.accountmanagement.companyoverview
        },
        {
          name: 'Warehouses',
          route: AppConstants.APP_ROUTES.accountmanagement.AcccountManagementWarehouse
        },
        {
          name: 'Clients',
          route: AppConstants.APP_ROUTES.accountmanagement.Managementclients
        },
        {
          name: 'Users',
        route: AppConstants.APP_ROUTES.accountmanagement.ManagementUsers
         },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.accountmanagement.ManagementSettings
        }

      ],
    },
    ordermanagement: {
      title: 'Order Management',
      title2: 'Order Management',
      name: 'ordermanagement',
      index: 6,
      hasPanel: true,
      isLinkExpanded: false,
      links: [
        {
          name: 'Order Overview',
          route: AppConstants.APP_ROUTES.ordermanagement.default
        },
        {
          name: 'Failed Order Queue',
          route: AppConstants.APP_ROUTES.ordermanagement.failedOrderQueue
        }

      ],


    },
    account:{
      title: 'account',
      title1: 'Client',
       name: 'account',
      index: 7,
      hasPanel: true,
      isLinkExpanded: false,
      links: [

        {
          name: 'Warehouses',
          route: AppConstants.APP_ROUTES.account.default
        },
        {
          name: 'Clients',
          route: AppConstants.APP_ROUTES.account.clients
        },
        {
          name: 'Users',
          route: AppConstants.APP_ROUTES.account.users
        },
        {
          name: 'Roles',
          route: AppConstants.APP_ROUTES.account.roles
        },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.account.seetings
        },

      ],

       Clientlinks: [

        {
          name: 'Overview',
          route: AppConstants.APP_ROUTES.account.clientoverview
        },
        {
          name: 'Items',
          route: AppConstants.APP_ROUTES.account.itemmaster
        },
        {
          name: 'Suppliers',
          route: AppConstants.APP_ROUTES.account.suppliers
        },
        {
          name: 'Ship Methods',
        route: AppConstants.APP_ROUTES.account.carrier
         },

        {
          name: 'Customers',
          route: AppConstants.APP_ROUTES.account.customers
        },
        {
          name: 'Settings',
          route: AppConstants.APP_ROUTES.account.clientSettings
        }

      ],







    },


    uikit: {
      title: 'uikit',
      name: 'uikit',
      index: 8,
      hasPanel: false,
      isLinkExpanded: false,
    },

    // Mobile Menu Options
    asn : {
      title: 'asn',
      name: 'asn',
      index: 9,
      hasPanel: true,
      isLinkExpanded: false,
      isCaps: true,
      links: [
        {
          name: 'Unload',
          route: AppConstants.APP_ROUTES.asn.default,
        },
        {
          name: 'Receive',
          route: AppConstants.APP_ROUTES.asn.receive,
        },
        {
          name: 'Putaway',
          route: AppConstants.APP_ROUTES.asn.putaway,
        },
      ]
    },
    pick: {
      title: 'pick',
      name: 'Pick',
      index: 10,
      hasPanel: true,
      isLinkExpanded: false,
      isCaps: false,
      links: [
        {
          name: 'Orders',
          route: AppConstants.APP_ROUTES.pick.scanOrder,
        },
        {
          name: 'Batches',
          route: AppConstants.APP_ROUTES.pick.scanBatch,
        },

      ]
    },


  };
  public static GRID_FILTER_LABLES = {
    all: 'All',
    pending: 'Pending',
    allocated: 'Allocated',
    partiallyAllocated: 'Partially Allocated',
    readyToPick: 'Ready to Pick',
    delayed: 'Delayed',
    dueNow: 'Due now',
    future: 'Future',
    pick: 'Pick',
    pack: 'Pack',
    completedToday: 'Completed'
  };
  public static ROLES={
    CompanyAdmin:'admin',
    SuperAdmin:'superadmin',
    V1User:'admin'
  }
  public static APIs={
    subscriptions:{url:'/subscriptions/',params:'?limit=99999'},
    clients:{url:'/clients',params:'?limit=99999'},
    suppliers:{url:'/suppliers/',params:'?limit=99999'},
    shipmethods: {url:'/ship-methods/',params:'?limit=99999'},
    integration:{url:'/shipping-providers',params:'?limit=99999'},
    companyAccount:{url:'/accounts/',params:'?limit=99999'},
    login:{url:'/login/',params:''},
    account_subscription_mapping:{url:'/account-subscription-mapping/',params:'?limit=99999'},
    configrations:{url:'/configurations/',params:'?limit=99999'},
    setupLookups:{url:'/setupLookups/',params:'?lookup_type='},
    countries:{url:'/countries/',params:''},

    // SHIP METHOD LOOKUPS
    shipMethodCarrierLookup: {url:'/setup-lookups?lookup_type=scac code',params:'?limit=99999'},
    shipMethodServiceLookup: {url:'/setup-lookups?lookup_type=service level',params:'?limit=99999'},
    shipMethodStatusLookup: {url:'/setup-lookups?lookup_type=ship method status',params:'?limit=99999'},

  };

  public static ActiveStatus= {
    Active:'Active',
    InActive:'InActive',
  };
}


const MENU = [

  {
    id: 1,
    name: 'Dashboard', // to be used in Menu and possibly for breadcrumbs
    defaultUrl: 'dashboard',  // to be used in url
    iconUrl: ' ', // icons
    toolTip: 'Dashboard', // to be shown as tool tip
    isWeb: true, // true for websites, false for mobile
    subMenu: [
      // empty array indicating no child
    ]
  },

  {
    id: 2,
    name: 'Inbound', // to be used in Menu and possibly for breadcrumbs
    defaultUrl: 'inbound',  // to be used in url
    iconUrl: ' ', // icons
    toolTip: 'Inbound', // to be shown as tool tip
    isWeb: true, // true for websites, false for mobile
    subMenu: [
      {
        id: 1,
        name: 'Overview | 721', // to be used in Menu and attach it with breadcrumb
        url: 'overview', // to be attached with defaultUrl to render component
        subChild: [
        // empty array as this has no further subchilds
        ]
      },
      {
        id: 1,
        name: 'Pick | 724', // to be used in Menu and attach it with breadcrumb
        url: 'pick', // to be attached with defaultUrl to render component
        subChild: [
          {
            id: 1,
            name: 'Orders', // to be used in Menu and attach it with breadcrumb
            url: 'orders', // to be attached with defaultUrl+url to render component
          },
          {
            id: 2,
            name: 'Freight',
            url: 'freight',
          }
        ]
      }
    ]
  }
 ]
