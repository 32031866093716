<div class="container-fluid">
  <div class="row justify-content-between border-bottom">
    <div class="col-9 pl-3">
      <h2 mat-dialog-title>
        {{
          moduleName == "Stock" 
            ? "Add SKU"
            : moduleName == "kitOrders"
            ? "Add Kit"
            : "Add SKU"
        }}
      </h2>
      <!-- <h2 mat-dialog-title>Add Product</h2> -->
    </div>

    <div class="col-3 pr-3 text-right">
      <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col-xs-5 col-lg-5 mt-4">
        <mat-form-field
          class="example-full-width prod-search"
          appearance="outline"
        >
          <input
            #inputProductRef
            matInput
            [(ngModel)]="search_products_array_model"
            name="search_products_array_model"
            placeholder="Search SKUs"
            [placeholder]="moduleName === 'kitOrders' ? 'Search kit' : 'Search SKUs'"
            #input
            (keyup)="handleEmptySearch($event)"
            (keyup.enter)="debounceProduct($event)"
            (keyup)="disableBtn($event); debounceProduct($event)"
          />
          <mat-icon
            (click)="searchProduct(search_products_array_model)"
            matSuffix
            style="cursor: pointer"
            >search</mat-icon
          >
        </mat-form-field>
      </div>

      <div
        class="col-12 po-height"
        *ngIf="
          search_products_array_model !== undefined &&
          search_products_array_model !== ''
        "
      >
        <shtiks-list-updated
          #prodListItems
          defaultSort="AccountID"
          SelectableRows="true"
          [customCssClass]="'popup-grid'"
          [MasterColumns]="columnsToDisplay"
          [PaginationData]="paginationDataSource"
          [GridData]="gridDataSource"
          [hasMoreHeader]="true"
          (pagechange)="pageChanges($event)"
          (pagesize)="pageSizeForList($event)"
          (actionClick)="actionClick($event)"
          (RowSelected)="selectedRow($event)"
          (AllSelected)="allselectedRow($event)"
          [ClearSelection]="clearSelection.asObservable()"
          [ModuleName]="moduleName"
        >
        </shtiks-list-updated>
      </div>

      <div
        class="col-12 po-height text-center"
        *ngIf="
          search_products_array_model == undefined ||
          search_products_array_model == ''
        "
      >
        <h3 class="f-s-18" *ngIf="moduleName === 'SO'">Search your desired SKU</h3>
        <h3 class="f-s-18" *ngIf="moduleName !== 'SO'">No {{moduleName === "kitOrders" ?"Kit":"SKUs"}} To Display</h3>

      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end align-items-center w-100 px-3">
      <!-- <button mat-flat-button class="btn-outlined" (click)="closeDialog('')">Close</button> -->
      <button
        mat-flat-button
        class=" btn-main custom-btn-width"
        (click)="addproduct('AddNCont')"
        [disabled]="!(this.products_array.length > 0)"
      >
      {{moduleName === "SO" ?"Add":"Add Selected"}}
        
      </button>
      <button
        mat-flat-button
        class="btn-outlined"
        (click)="addproduct('AddNClose')"
        [disabled]="!(this.products_array.length > 0)"
      >
        Add & Close ({{ products_array.length }})
      </button>
    </div>
  </mat-dialog-actions>
</div>
