import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { AttachmentComponent } from '../attachment/attachment.component';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { NotesComponent } from '../notes/notes.component';
import { AddProductpopupComponent } from '../../shared/add-productpopup/add-productpopup.component';
import { debounce } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfirmationComponent } from 'src/app/pages/shared/confirmation/confirmation.component';


export interface PeriodicElement {
  id: any;
  idForDelete: any;
  po_no: any;
  fulfillment_no: any;
  category: any;
  brand: any;
  ord_no: any;
  purchase_order_id: any;
  proposed_cost: any; //Sales Request
  product_id: any;
  attachment: any;
  product_name: any;
  product_sku: any;
  quantity: any;
  cost: any;
  uom_id: any;
  uom_name: any;
  base_unit: any;
  list_price: any;
  requested_delivery_date: any;
  is_in_fulfillment?: any;
  total_quantity: any;
  vendor_id: any;
  vendor_name: any;
  from: any;
  from_id: any;
  availbleQty: any;
  receivedQuantity: any;
  warehouse_id: any;
  warehouse_name: any;

  inventory_available_quantity: any; //salesOrder
  fulfillment_quantity: any; //salesOrder
  back_order_quantity: any; //salesOrder

  notes: any;
  attachments: any;
  requested_delivery_date_Display: Date;
}

@Component({
  selector: "app-lines-items",
  templateUrl: "./line-items.component.html",
  styleUrls: ["./line-items.component.scss"],
})
export class LinesItemsComponent implements OnInit, OnChanges, OnDestroy {
  todayDate = new Date();

  displayedColumns: any;
  disabledColumns: any;
  moduleName: any;
  total_quantity: any;
  transferBy: any;

  isEditView: boolean = false;
  isCreateView: boolean = false;
  isDetailView: boolean = false;
  status: any;
  products_array: PeriodicElement[] = [];
  initialData: any = [];
  requestedLines: any = [];
  inValidData: any;
  client_Id: any;
  Api_Url: any;
  warehouse_id: any;

  lastSearchedProduct: any;
  fromLocationList: any;
  from: any;
  selectedLocationId: number;
  selectedLocation: any;
  toLocationList: any;
  availbleQty: string;
  productId: any;
  from_id: any;
  from_name: any;
  name: any;
  kitName: any;
  sku: any;
  assignedTo: any;
  assigned_to: any;

  @Input("ApiUrl") set LineItemApiUrl(value: any) {
    this.Api_Url = value;
  }

  @Input("clientId") set clientIDKey(value: any) {
    this.client_Id = value;
    ////  console.log('changin', value, this.client_Id)
    this.getwarehouse();
  }

  @Input("warehouseId") set warehouseIDKey(value: any) {
    this.warehouse_id = value;
    this.getlocation();
  }

  @Input("validProductList") set validData(value: any) {
    this.inValidData = value;
    // this.disabledColumns = value;
  }

  @Input("ModuleName") set module(value: any) {
    this.moduleName = value;
  }

  @Input("TransferBy") set transfer(value: any) {
    this.transferBy = value;
  }
  @Input("assignedTo") set assignTo(value: any) {
    this.assignedTo = value;
  }

  @Input("DisplayColumns") set cols(value: any) {
    this.displayedColumns = value;
  }
  @Input("DisabledColumns") set disCols(value: any) {
    this.disabledColumns = value;
  }

  @Input("GridData") set Data(value: any) {
    this.products_array = value;

    if (value) {
      this.initialData = JSON.parse(JSON.stringify(value));
    }

    this.products_array?.forEach((e, index) => {
      e.requested_delivery_date_Display = value[index].requested_delivery_date;
      e.brand = value[index].product?.brand_name;
      e.category = value[index].product?.category_name;
      this.moduleName == "Stock" ? (e.quantity = value[index].qty) : "";
    });
    console.log(this.products_array, "this.products_array from stock detail");

    this.calcNetTotal();
    this.calcTotalQty();
    this.calcTotalPickQty();
  }
  @Input() isEditDiscrepancy: boolean;
  @Output() isToggledChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input("Status") set getStatus(value: any) {
    this.status = value;
  }
  @Input("EditMode") set EditModeData(value: any) {
    this.isEditView = value;
  }
  @Input("CreateMode") set createModeData(value: any) {
    this.isCreateView = value;
  }
  @Input("DetailMode") set detailModeData(value: any) {
    this.isDetailView = value;
  }

  @Output("onPoClick") onPoClick = new EventEmitter<string | number>();

  @Output("CreatePOfromPR") createPOfromPR = new EventEmitter<any>();

  @Output("SelectedItems") selectedItems = new EventEmitter<any>();

  @Output("ReturnDataToParent") returnDataToParent = new EventEmitter<any>();

  @Output("GetDetail") getDetail = new EventEmitter<any>();

  showAutocompletepro: boolean = false;
  search_products_array: any = [];
  filtered_search_products_array: any = [];
  uom_list: any;
  vendorList: any;
  warehouseList: any;
  locationList: any;

  selection = new SelectionModel<PeriodicElement>(true, []);

  @ViewChild(MatTable) table!: MatTable<PeriodicElement>;

  search_products_array_model: any;

  totalQty = 0;
  NetTotal = 0;
  totalPickQty = 0;
  NetTotalDisplay = "";
  totalQtyDisplay = "";
  totalPickQtyDisplay = "";

  polineHaveSameVendor: any;

  constructor(
    public service: ConfigService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public snackbarService: CustomSnackbarService,
    public alert: AlertService,
    private NgxLoader: NgxUiLoaderService
  ) {
    this.debounceProduct = debounce(this.debounceProduct, 2000);
    //  console.log(this.client_Id)
  }

  ngOnInit(): void {
    this.getvendors();
    this.getuom();
    // console.log('isEditDiscrepancy', this.isEditDiscrepancy)

    // this.client_Id.subscribe((message: any) => {
    //  //  console.log('changin')
    //   if (message !== this.client_Id) {
    //    //  console.log('changin', message, this.client_Id)
    //     this.getwarehouse();
    //     // this.myVar = message;
    //     // this.doSomething();
    //   }
    // });

    // if (this.isDetailView && this.status == 'rfq') {
    //   this.getvendors();
    // } else if (!this.isDetailView) {
    //   this.getvendors();
    //   this.getuom();
    //   this.getwarehouse();
    // }
    this.service.selectedProducts.subscribe((data: any) => {
      if (data.length > 0) {
        this.updateGridData(data);
        this.calcTotalQty();
        this.calcNetTotal();
        this.getfromlocation();
        this.alert.show("SKU(s) added into line SKUs", "success");
      }
      console.log("Response From Service", data);
    });
  }

  autoCompleteFilterClient(value: any) {
    if (typeof value == "string") {
      const filterValue = value?.toLowerCase();
      this.filtered_search_products_array = this.search_products_array.filter(
        (option: any) => {
          return option.name.toLowerCase().includes(filterValue);
        }
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // This added to uncheck master toggle when fulfilement created against any order
    if (
      (this.status == "new" ||
        this.status == "partially" ||
        this.status == "assigned" ||
        this.status == "in-fulfillment") &&
      this.moduleName == "SO"
    ) {
      this.selection.clear();
      //  console.log(this.selection.hasValue());
    }
  }

  ngOnDestroy(): void {
    this.service.setProducts([]);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.products_array?.length;
    return numSelected === numRows;
  }

  masterToggle(e: any) {
    if (!e.checked && this.status == "rfq" && this.moduleName == "PR") {
      this.selection.clear();
      return;
    }

    if (
      !e.checked &&
      (this.status == "new" ||
        this.status == "partially" ||
        this.status == "assigned" ||
        this.status == "in-fulfillment") &&
      this.moduleName == "SO"
    ) {
      this.selection.clear();
      this.selectedItems.emit(this.selection.selected);
      return;
    }

    if (!e.checked && this.moduleName == "PO") {
      this.selection.clear();
      this.selectedItems.emit(this.selection.selected);
      return;
    }

    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    if (this.status == "rfq" && this.moduleName == "PR") {
      this.selection.select(
        ...this.products_array.filter((x) => x.po_no == null)
      );
      this.selectedItems.emit(this.selection.selected);
    } else if (
      (this.status == "new" ||
        this.status == "partially" ||
        this.status == "assigned" ||
        this.status == "in-fulfillment") &&
      this.moduleName == "SO"
    ) {
      this.selection.select(
        ...this.products_array.filter((x) => x?.is_in_fulfillment == false)
      );
      this.selectedItems.emit(this.selection.selected);
    } else if (this.moduleName == "PO") {
      console.log("hit");
      this.selection.select(...this.products_array);
      this.selection.select(
        ...this.products_array.filter((x) => x?.is_in_fulfillment == false)
      );
      this.selectedItems.emit(this.selection.selected);
    } else {
      this.selection.select(...this.products_array);
    }
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.product_id + 1
    }`;
  }

  getproductsToOrderList(e: any) {
    this.showAutocompletepro = false;
    if (e?.id) {
      let productObject: PeriodicElement = {
        idForDelete: "",
        id: e.id,
        attachment: e.attachment,
        product_name: e.name,
        product_sku: e.sku,
        quantity: "",
        cost: e.site_cost,
        from: e.from,
        from_id: e.from_id,
        availbleQty: e.availbleQty,
        requested_delivery_date: new Date(),
        requested_delivery_date_Display: new Date(),
        brand: e?.brand_name,
        category: e?.category_name,
        po_no: "",
        fulfillment_no: "",
        receivedQuantity: e.received_Quantity,
        ord_no: "",
        purchase_order_id: "",
        proposed_cost: "",
        product_id: e.product_id,
        uom_id: "",
        uom_name: "",

        base_unit: e.base_unit,
        list_price: e.list_price,
        total_quantity: e.total_quantity,
        vendor_id: null,
        vendor_name: "",

        warehouse_id: "",
        warehouse_name: "",

        inventory_available_quantity: "",
        fulfillment_quantity: "",
        back_order_quantity: "",

        notes: "",
        attachments: [],
      };
      this.products_array.push(productObject);
      this.table.renderRows();

      // this.search_products_array = this.products_array;
      //  this.showAutocompletepro = false;
      this.search_products_array_model = [];

      this.sendDataToParent();
    } else if (e?.length >= 1) {
      this.showAutocompletepro = true;
    }
    //else if (e?.length >= 3) {
    //   this.getProducts(e);
    //   this.showAutocompletepro = true;
    // } else {
    //   this.showAutocompletepro = false;
    // }
  }

  debounceProduct() {
    this.getProducts();
  }
  /**---- Lookups calls START------ */
  async getProducts() {
    let productApi =
      `/api/products/lookup?term=` + this.search_products_array_model;
    if (this.client_Id && this.service.accountTypeAPI === "wms_service") {
      productApi =
        "/api/products/lookup?term=" +
        this.search_products_array_model +
        "&client_Id=" +
        this.client_Id;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(productApi, "");

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;
    } else if (response.data.data.length <= 0) {
    } else {
      this.alert.show(response.error.message, "error");
    }
  }

  async getuom() {
    const response: any = await this.service.getApi("/api/uoms/lookup", {});
    if (response.success && response.data.length > 0) {
      this.uom_list = response.data;
    } else {
      if (response.data.length <= 0) {
        //  this.alert.show('No data record found for selected criteria', 'error');
      } else {
        this.alert.show(response.error.message, "error");
      }
    }
  }

  async getvendors() {
    const response: any = await this.service.getApi("/api/vendors/lookup", {});
    if (response.status && response.data.length > 0) {
      this.vendorList = response.data;
    } else if (response.status && response.data.length <= 0) {
      //   this.alert.show('No data record found for selected criteria', 'error');
    } else {
      this.alert.show(response.error.message, "error");
    }
  }

  async getwarehouse() {
    let warehouseApi = "/api/warehouse/lookup";

    if (this.client_Id && this.service.accountTypeAPI === "wms_service") {
      warehouseApi = "/api/warehouse/lookup" + "?client_id=" + this.client_Id;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(warehouseApi, {});
    if (response.status && response.data) {
      this.warehouseList = response.data;
    } else {
      if (response.data?.length <= 0) {
        //    this.alert.show('No data record found for selected criteria', 'success');
      }
      this.alert.show(response.error.message, "error");
    }
  }

  /**---- Lookups calls END------ */
  onSelectionChange(e: any, i: any) {
    this.selectedLocationId = e;
    this.selectedLocation = this.fromLocationList[
      this.products_array[i].product_id
    ].find((item: any) => item.location_id == this.selectedLocationId);

    if (this.selectedLocation) {
      this.products_array[i].from = this.selectedLocation.location_name;
      this.products_array[i].from_id = this.selectedLocation.location_id;
      this.products_array[i].availbleQty = this.selectedLocation.available;
    } else {
      this.products_array[i].from = "";
      this.products_array[i].from_id = "";
      this.products_array[i].availbleQty = "";
    }

    this.toLocationList = this.locationList; // refresh
    this.toLocationList = this.locationList.filter(
      (item: { name: any }) => item.name !== this.selectedLocation.location_name
    ); //filter out selected data from to location
  }

  async getfromlocation() {
    if (this.moduleName === "Stock") {
      const productIds = this.products_array.map((item) => item.product_id);

      this.fromLocationList = [];

      for (const productId of productIds) {
        let fromlocationApi = `/api/inventories/locations?client_id=${this.client_Id}&product_id=${productId}&warehouse_id=${this.warehouse_id}`;

        const response: any = await this.service.getApi(fromlocationApi, {});

        if (response.status && response.data) {
          // Ensure that the locations are stored in a map with product_id as key
          this.fromLocationList[productId] = response.data.data;
        } else {
          if (response.data?.length <= 0) {
            // Handle case when no data is returned
          }
          this.alert.show(response.error.message, "error");
        }
      }
    }
  }

  async getlocation() {
    let locationApi = "/api/location/lookup";

    if (this.client_Id && this.service.accountTypeAPI === "wms_service") {
      locationApi =
        "/api/location/lookup" +
        "?type=storage&warehouse_id=" +
        this.warehouse_id;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(locationApi, {});
    if (response.status && response.data) {
      this.locationList = response.data;
    } else {
      if (response.data?.length <= 0) {
        //    this.alert.show('No data record found for selected criteria', 'success');
      }
      this.alert.show(response.error.message, "error");
    }
    // if (this.moduleName == 'Stock') {
    //   var formLocation: any;
    //   if (this.products_array) {
    //     this.products_array.forEach((item: any) => {
    //       formLocation = item.from;
    //     });
    //   }
    //   this.locationList = this.locationList.filter(function (item: any) {
    //     return item.name != formLocation;
    //   });
    // }
  }

  itemDisplayFn(item: any) {
    return item ? item.name : "";
  }

  calcTotalQty() {
    this.totalQty = 0;
    this.products_array?.forEach((row) => {
      //  console.log(row)
      this.totalQty += Number(row.quantity ? row.quantity : 0);
    });
    this.totalQty = Math.round((this.totalQty + Number.EPSILON) * 100) / 100;
    this.totalQtyDisplay = this.totalQty.toLocaleString();
  }

  calcTotalPickQty() {
    this.totalPickQty = 0;
    this.products_array?.forEach((row) => {
      //  console.log(row)
      this.totalPickQty += Number(row.total_quantity ? row.total_quantity : 0);
    });

    this.totalPickQty =
      Math.round((this.totalPickQty + Number.EPSILON) * 100) / 100;
    this.totalPickQtyDisplay = this.totalPickQty.toLocaleString();
    //  console.log(this.totalQtyDisplay)
  }

  calcNetTotal() {
    this.NetTotal = 0;
    this.products_array?.forEach((row) => {
      //  console.log('row.quantity', row.quantity, 'row.cost', row.cost)
      //   Number(row.quantity) * Number(row.cost) * Number(row.proposed_cost);
      this.NetTotal +=
        Number(row.quantity ? row.quantity : 0) *
        Number(row.cost ? row.cost : 0);
      //  console.log(this.NetTotal)
    });

    this.NetTotal = Math.round((this.NetTotal + Number.EPSILON) * 100) / 100;
    this.NetTotalDisplay = this.NetTotal.toLocaleString();
  }

  opennotes(e: any) {
    let detailMode = false;
    if (this.isDetailView) {
      detailMode = true;
    }

    const dialogRefNotes = this.dialog.open(NotesComponent, {
      width: "384px",
      height: "231px",
      data: { notes: e.notes ? e.notes : "", detailmode: detailMode },
    });

    dialogRefNotes.beforeClosed().subscribe((data) => {
      if (data) {
        e.notes = data;
        this.sendDataToParent();
      } else {
      }
    });
  }

  openattachment(e: any) {
    let detailMode = false;
    if (this.isDetailView) {
      detailMode = true;
    }

    const dialogrefAttachment = this.dialog.open(AttachmentComponent, {
      width: "662px",
      data: {
        attachments: e.attachments,
        detailmode: detailMode,
        title: "Attachment",
      },
    });
    dialogrefAttachment.afterClosed().subscribe((data) => {
      if (data) {
        e.attachments = data;
        this.sendDataToParent();
      }
    });
  }

  openuploadlabel(e: any) {
    let detailMode = false;
    if (this.isDetailView) {
      detailMode = true;
    }
    const dialogrefAttachment = this.dialog.open(AttachmentComponent, {
      width: "662px",
      data: {
        attachments: e.attachments,
        detailmode: detailMode,
        title: "Upload Label",
      },
    });
    dialogrefAttachment.beforeClosed().subscribe((data) => {
      if (data) {
        e.attachments = data;
        this.sendDataToParent();
      }
    });
  }

  /**------------------UPDATE PO LINES------------------- */

  async updatePOlines(data: any) {
    if (data.id) {
      const data1: any = await this.service.putApi(
        "/api/purchaseRequestLines/" + data.id,
        data
      );
      if (data1.success && data1.status == 200) {
        this.alert.show("POLines updated", "success");
        //   this.checkOnVendorChange();
      } else {
        this.alert.show(data1.message, "error");
      }
    }
  }

  onchangeCostVendor(e: any, element: PeriodicElement, event?: any) {
    if (
      this.status == "rfq" &&
      this.isEditView == false &&
      this.isCreateView == false
    ) {
      if (event) {
        element.vendor_id = event;
      }
      if (e || event) {
        if (element.requested_delivery_date_Display) {
          element.requested_delivery_date = this.datePipe.transform(
            element.requested_delivery_date_Display,
            "yyyy-MM-dd hh:mm:ss"
          );
        } else {
          this.alert.show("Enter Date in PO line", "error");
        }

        this.updatePOlines(element);
      }

      this.products_array;
    }
  }

  /**------------------Create PO From PR------------------- */
  // checkselectedPOlines() {
  //   this.polineHaveSameVendor = true;

  //   if (this.selection.selected.length > 0) {

  //     this.selection.selected.forEach((e: PeriodicElement) => {
  //       if (e.vendor_id !== this.selection.selected[0].vendor_id) {
  //         this.polineHaveSameVendor = false;
  //       }
  //     })

  //   } else {

  //     this.products_array.forEach(element => {
  //       if (element.vendor_id != this.products_array[0].vendor_id) {
  //         this.polineHaveSameVendor = false;
  //       }
  //     })

  //   }

  //   this.createPOfromPR.emit(this.polineHaveSameVendor)
  //   this.selectedItems.emit(this.selection.selected);

  // }

  // checkOnVendorChange() {
  //   if (this.status == 'rfq') {
  //     this.polineHaveSameVendor = true;
  //     this.createPOfromPR.emit(this.polineHaveSameVendor)

  //     this.products_array.forEach((element) => {
  //       if (element.vendor_id != this.products_array[0].vendor_id) {
  //         this.polineHaveSameVendor = false;
  //         this.createPOfromPR.emit(this.polineHaveSameVendor)
  //       }
  //     })
  //   }
  // }

  sendDataToParent() {
    //  console.log("sendDataToParent", this.products_array);
    // if (this.moduleName == 'Stock') {
    //   var formLocation: any;
    //   this.products_array.forEach((item: any) => {
    //     formLocation = item.from;
    //     console.log(item.from,'item.from;');

    //   });
    //   this.locationList = this.locationList.filter(function (item: any) {
    //     return item.name != formLocation;
    //   });
    // }
    // console.log('sending', this.products_array);
    this.returnDataToParent.emit(this.products_array);
  }
  async removePORow(e: any, element: any) {
    console.log("module-name", this.moduleName);

    if (this.moduleName === "SO") {
      // Show confirmation dialog only if moduleName is 'SO=Sale Order may b we apply on all the module'
      const dialogRef2 = this.dialog.open(ConfirmationComponent, {
        width: "384",
        height: "231px",
        backdropClass: "backdrop",
        disableClose: false,
        data: { message: `Are you sure to delete this SKU ?` },
      });

      // Handle the dialog close event
      dialogRef2.beforeClosed().subscribe(async (data) => {
        if (data === "1") {
          await this.handleDeletion(e, element);
        }
      });
    } else {
      await this.handleDeletion(e, element);
    }
  }

  async handleDeletion(e: any, element: any) {
    if (this.Api_Url !== "none") {
      if (!element.isLocalProduct) {
        this.NgxLoader.start();
        const response = await this.service.deleteApi(
          this.Api_Url + "/" + element.idForDelete,
          {}
        );
        if (response.status === 200 && response.success === true) {
          this.alert.show(response.message, "success");
          this.products_array = this.products_array.filter(
            (item, index) => index !== e
          );
          //this.callDetail();
        } else if (response.status === 403) {
          this.alert.show(response.error.message, "error");
        } else {
          this.alert.show(response.message, "error");
        }
        this.NgxLoader.stop();
      } else {
        this.products_array = this.products_array.filter(
          (item, index) => index !== e
        );
      }
      this.calcTotalQty();
      this.calcNetTotal();
      this.sendDataToParent();
    } else if (this.Api_Url == "none") {
      this.products_array = this.products_array.filter(
        (item, index) => index !== e
      );
      // console.log("products_array", this.products_array);
      this.calcTotalQty();
      this.calcNetTotal();
      this.sendDataToParent();
    } else {
      this.alert.show("Unable to delete record", "error");
    }
  }

  maxLimitForQty(current: any, max: any) {
    if (this.moduleName == "ASN") {
      if (current.quantity > max) {
        current.quantity = max;
      }
    }
    if (this.moduleName == "Stock") {
      if (current.quantity > current.availbleQty) {
        current.quantity = current.availbleQty;
      }
    }
  }

  maxLimitForCost(current: any) {
    let max = 100000;
    // if (current.cost > max || current.proposed_cost > max) {
    //   this.alert.show('Max Limit for Cost is 100000', 'error');
    // }
  }

  maxLimitForQtyGeneral(current: any) {
    let max = 100000;
    // if (current.quantity > max) {
    //   this.alert.show('Max Limit for Quantity is 100000', 'error');
    // }
  }

  checkselectedlines() {
    this.selectedItems.emit(this.selection.selected);
  }

  callDetail() {
    this.getDetail.emit();
  }

  handleImgError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = "../../../../assets/images/default-image.png";
    target.onerror = null; // Prevents infinite loop if the fallback image also fails
  }

  updateGridData(data: any, getDetail?: boolean) {
    const storedWarehouseId = localStorage.getItem("warehouse_id");
    let warehouseIdArray: number[] = [];
    let assignedWarehouseId: number | null = null;

    if (storedWarehouseId) {
      warehouseIdArray = JSON.parse(storedWarehouseId);
      if (warehouseIdArray.length == 1) {
        data[0].warehouse_id = warehouseIdArray[0];
        this.getProductsQuantity(data[0], null);
        assignedWarehouseId = warehouseIdArray[0];
      }
    }

    if (this.moduleName == "cycleCount" || this.moduleName == "cycleCountLoc") {
      this.products_array = [];
    }

    if (getDetail) {
      this.products_array = [];
    }

    if (!Array.isArray(this.products_array)) {
      this.products_array = []; // Initialize products_array if it's not an array
    }

    if (data && data.length > 0) {
      data.forEach((e: any) => {
        if (e && e.cost) {
          e.cost = e.cost.replace("$ ", "");
        }

        let indx = -1;
        if (this.moduleName != "assembly") {
          indx = this.products_array.findIndex(
            (p) => p.product_sku == e?.product_sku
          );
        }

        if (indx !== -1) {
          let comingQTY =
            e?.quantity !== undefined &&
            e?.quantity !== null &&
            e?.quantity !== ""
              ? e.quantity
              : 0;

          if (
            this.products_array[indx].quantity == "" &&
            e?.quantity !== undefined
          ) {
            this.products_array[indx].quantity = comingQTY;
          } else {
            this.products_array[indx].quantity += comingQTY;
          }
        } else {
          let productObject: any = {
            idForDelete: e?.id,
            isLocalProduct: e?.isLocalProduct,
            IDforAttachment: e?.id,
            id: e.product_id,
            product_id: e.product_id,
            product_name: e.product_name,
            no_of_components: e?.no_of_components,
            no_of_locations: e?.no_of_locations,
            upc: e.upc,
            attachment: e.featured_image ? e.featured_image : e.attachment,
            product_sku: e.product_sku,
            receivedQuantity: e.received_quantity,
            quantity: e?.quantity,
            cost: e.cost,
            uom_id: e.uom_id,
            uom_name: e.base_unit ? e.base_unit : e.uom_name,
            base_unit: e.base_unit,
            brand: e.brand,
            category: e.category,
            requested_delivery_date_Display: new Date(
              e.requested_delivery_date
            ),
            requested_delivery_date: new Date(e.requested_delivery_date),
            inventory_available_quantity: "",
            vendor_id: e.vendor_id == "" ? null : e.vendor_id,
            notes: e.notes,
            attachments: e.attachments,
            on_hand_qty: e.on_hand_qty,
            qty: e.qty,
            from: e.from,
            from_id: e.from_id,
            to: e.to,
            availbleQty: e.availbleQty,
            warehouse_id: this.isCreateView ? assignedWarehouseId : null,
          };
          this.products_array.push(productObject);
        }
      });
    }
    console.log(this.products_array, "this.products_array");

    this.requestedLines = this.products_array;

    if (this.moduleName != "cycleCount" && this.moduleName != "cycleCountLoc") {
      this.table.renderRows();
    }
  }

  polines() {
    if (
      !this.client_Id &&
      (this.moduleName == "cycleCount" || this.moduleName == "cycleCountLoc")
    ) {
      this.alert.show("Please select any client", "error");
      return;
    }
    if (
      !this.warehouse_id &&
      (this.moduleName == "cycleCount" || this.moduleName == "cycleCountLoc")
    ) {
      this.alert.show("Please select any warehouse", "error");
      return;
    }
    if (!this.client_Id && this.moduleName == "kitOrders") {
      this.alert.show("Please select any client", "error");
      return;
    }
    if (!this.warehouse_id && this.moduleName == "kitOrders") {
      this.alert.show("Please select any warehouse", "error");
      return;
    }
    if (!this.client_Id && this.moduleName == "Stock") {
      this.alert.show("Please select any client", "error");
      return;
    }

    if (!this.warehouse_id && this.moduleName == "Stock") {
      this.alert.show("Please select any warehouse", "error");
      return;
    }
    if (!this.transferBy && this.moduleName == "Stock") {
      this.alert.show("Please select transfer by", "error");
      return;
    }
    if (!this.client_Id && this.moduleName == "assembly") {
      this.alert.show("Please select any client", "error");
      return;
    }
    if (
      this.client_Id &&
      (this.moduleName == "cycleCount" || this.moduleName == "cycleCountLoc")
    ) {
      console.log("test");
      // debugger
      this.requestedLines = [];
      const podialogRef = this.dialog.open(AddProductpopupComponent, {
        data: {
          selectedProducts: this.products_array,
          client_id: this.client_Id,
          warehouse_Id: this.warehouse_id,
          // assigned_to: this.assigned_to,
          moduleName: this.moduleName,
        },
        width: "862px",
      });

      podialogRef?.afterClosed().subscribe((result: any) => {
        console.log('result', result);

        if (result) {
          this.updateGridData(result);
          this.calcTotalQty();
          this.calcNetTotal();
          this.sendDataToParent();
          this.getfromlocation();
        } else {
          this.requestedLines = this.products_array;
        }
      });
    } else if (this.client_Id && this.moduleName == "kitOrders") {
      //console.log('test');
      // debugger
      this.requestedLines = [];
      const podialogRef = this.dialog.open(AddProductpopupComponent, {
        data: {
          selectedProducts: this.products_array,
          client_id: this.client_Id,
          warehouse_Id: this.warehouse_id,
          assigned_to: this.assigned_to,
          moduleName: this.moduleName,
        },
        width: "862px",
      });

      podialogRef?.afterClosed().subscribe((result: any) => {
        //console.log('result', result);

        if (result) {
          this.updateGridData(result);
          this.calcTotalQty();
          this.calcNetTotal();
          this.sendDataToParent();
          this.getfromlocation();
        } else {
          this.requestedLines = this.products_array;
        }
      });
    } else if (this.client_Id && this.moduleName == "assembly") {
      this.requestedLines = [];
      const podialogRef = this.dialog.open(AddProductpopupComponent, {
        data: {
          selectedProducts: this.products_array,
          client_id: this.client_Id,
          moduleName: this.moduleName,
        },
        width: "862px",
      });

      podialogRef?.afterClosed().subscribe((result: any) => {
        //console.log('result', result);

        if (result) {
          this.updateGridData(result);
          this.calcTotalQty();
          this.calcNetTotal();
          this.sendDataToParent();
          this.getfromlocation();
        } else {
          this.requestedLines = this.products_array;
        }
      });
    } else if (this.client_Id) {
      this.requestedLines = [];
      const podialogRef = this.dialog.open(AddProductpopupComponent, {
        data: {
          selectedProducts: this.products_array,
          client_id: this.client_Id,
          moduleName: this.moduleName,
          warehouse_Id: this.warehouse_id,
          tranferBy: this.transferBy,
        },
        width: "862px",
      });

      podialogRef?.afterClosed().subscribe((result: any) => {
        console.log("result", result);

        if (result) {
          this.updateGridData(result);
          this.calcTotalQty();
          this.calcNetTotal();
          this.sendDataToParent();
          this.getfromlocation();
        } else {
          this.requestedLines = this.products_array;
        }
      });
    } else {
      if (this.moduleName == "PO") {
        this.alert.show("Please select the I.O From first", "error");
      }
      if (this.moduleName == "SO") {
        this.alert.show("Please select the Ship From first", "error");
      }
    }
  }

  clearSelection() {
    this.selection.clear();
    document
      .getElementById("master-chk")
      ?.classList.remove("mat-checkbox-indeterminate", "mat-checkbox-checked");
  }
  changeWarehouse(element: any) {
    console.log(element);

    if (this.moduleName == "ASN" || this.moduleName == "ASN-N") {
      // console.log(this.products_array[0].warehouse_id,'this.products_array[0].warehouse_id');
      this.products_array.forEach((x) => {
        x.warehouse_id = element.warehouse_id;
      });
    }
  }

  async getProductsQuantity(element: any, event: any) {
    this.changeWarehouse(element);
    const response: any = await this.service.getApi(
      "/api/inventories/warehouse/" +
        element?.warehouse_id +
        "?client_id=" +
        this.client_Id +
        "&sku=" +
        element?.product_sku,
      {}
    );

    if (response.status && response.data) {
      let ind = this.products_array.findIndex(
        (x) => x.product_sku == element?.product_sku
      );

      if (ind !== -1) {
        this.products_array[ind].inventory_available_quantity =
          response.data.available_quantity;
        this.table.renderRows();

        this.sendDataToParent();
      }
    } else {
      this.sendDataToParent();
      //this.alert.show(response.message, 'success');
    }
  }

  restrictInput(evt: any) {
    ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();
  }

  handleScroll() {
    if (
      document
        .querySelector(".form-height")
        ?.classList.contains("toggleMainScroll")
    ) {
      document
        .querySelector(".form-height")
        ?.classList.remove("toggleMainScroll");
      document
        .querySelector(".line-items-scroll")
        ?.setAttribute("style", "overflow-x:auto;");
    } else {
      // document.querySelector('.form-height')?.classList.add('toggleMainScroll');
      document
        .querySelector(".line-items-scroll")
        ?.setAttribute("style", "overflow-x:hidden; ");
    }
  }
  shouldShowTooltip(quantity: any): boolean {
    return quantity !== "-" && quantity !== null && quantity !== undefined;
  }
  getTooltipMessage(quantity: number): string {
    return quantity <= 0
      ? "This qty is not available in your selected warehouse"
      : "This is the available qty in your selected warehouse";
  }

  formatDate(date: string | Date): string {
    return new Date(date).toLocaleString();
  }

  navigateToPo(id: number | string) {
    this.onPoClick.emit(id);
  }
}
