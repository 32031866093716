import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {


  currentUser:any;
  constructor(public config: ConfigService) {
    this.currentUser = localStorage.getItem('access_token');
  }

  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    
    if(this.currentUser){
      return true;
    } else{
      return false;
    }
   

  }

}


