<div>
    <div class="d-flex justify-content-between align-items-center border-bottom mb-3 px-4 pb-2"
    *ngIf="!data.button">
    <ng-container *ngIf="data.heading==true ; else confirmHeading">
        <h2 mat-dialog-title class="mb-0">{{data.heading_text}}</h2>
    </ng-container>
    <ng-template #confirmHeading>
        <h2 mat-dialog-title class="mb-0">Confirmation</h2>
    </ng-template>
        <mat-icon mat-dialog-close>clear</mat-icon>
    </div>

    <mat-dialog-content class="mat-typography" style="width: 400px; display: flex; justify-content: center;">
        <div class="row mt-4" style="width: 320px; text-align: center;">
          <p>{{data.message}}</p>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions *ngIf="!data.heading_text">
        <div class="px-4">
            <ng-container *ngIf="data.button; else nobutton">
                <button mat-flat-button class="btn-main" (click)="closeDialog('1')">Ok</button>
            </ng-container>
            <ng-template #nobutton>
                <button mat-flat-button class="btn-outlined" (click)="closeDialog('0')">No</button>
                <button mat-flat-button class="mr-2 btn-main" (click)="closeDialog('1')">Yes</button>
            </ng-template>
        </div>
    </mat-dialog-actions>
</div>
