<div class="container-fluid">
    <div class="row d-flex align-items-center justify-content-between border-bottom p-4">
        <div class="col-9 pl-3">
            <h2 mat-dialog-title>Scan Log</h2>
        </div>

        <div class="col-3 text-right">
            <div class="d-flex align-items-center justify-content-end">
                <select (change)="changeOrder($event)" [(ngModel)]="selectedOption" class="history-order-select mr-4 bg-light">
                    <option value="Newest to oldest">Newest to oldest</option>
                    <option value="Oldest to newest">Oldest to newest</option>
                </select>
                <div class="cursor-pointer">
                    <mat-icon mat-dialog-close>clear</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="position-relative mb-3">
        <!-- <h4>{{scanLogs.title}}</h4> -->
        <div class="row justify-content-between pt-4 mr-4">
            <div class="col">
                <div class="card radius-10 border-0">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-center">
                            <div>
                                <p class="mb-0 text-center f-s-16">Job ID#</p>
                                <p class="mb-0 text-center text-muted">{{ firstRecord.task_id ? formatTaskId(firstRecord.task_id) : '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card border-start border-0 border-2 border-secondary">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Start Date & Time</p>
                                <p class="mb-0 font-weight-bold">{{ scanLogs.start_time ? formatDate(scanLogs.start_time) : '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10 border-start border-0 border-2 border-secondary">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">End Date & Time</p>
                                <p class="mb-0 font-weight-bold">{{ scanLogs.end_time ? formatDate(scanLogs.end_time) : '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10 border-start border-0 border-2 border-secondary">
                    <div class="card-body">
                        <div class="d-flex align-items-center justify-content-center">
                            <div>
                                <p class="mb-0 text-secondary">Duration</p>
                                <p class="mb-0 font-weight-bold">{{ scanLogs.duration ? scanLogs.duration : '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card radius-10 border-start border-0 border-2 border-secondary">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <p class="mb-0 text-secondary">Event</p>
                                <p class="mb-0 font-weight-bold text-capitalize">{{ firstRecord.event ? firstRecord.event : '-' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div (click)="exportLogs()" class="d-flex align-items-center justify-content-center bg-light position-absolute downloadButton cursor-pointer">
            <img src="../../../../../assets/images/download.svg" alt="" srcset="">
        </div>
    </div>

    <div class="p-4" style="background-color: #F8F9F9;">
        <div class="row radius-10 line-items-container">
            <app-lines-items [GridData]="scanLogsTableData" [EditMode]="false" [CreateMode]="false" [DetailMode]="true" 
              [DisplayColumns]="displayedColumnsforPOLines"
            ></app-lines-items>
        </div>
    </div>

    <!-- <div class="container mt-2" style="max-height: 280px;overflow: scroll;">
        <div class="row">
            <div class="col-lg-8 col-md-10 col-12">
                <div *ngFor="let date of dateArray">
                    <h5 class="mt-4"> <span class="p-2 bg-light shadow rounded text-dark">{{date}}</span>
                    </h5>
                    <ul class="mt-3">
                        <li *ngFor="let scanLog of scanLogs.data[date]" class="ml-3"
                            [ngClass]="{'text-danger': scanLog.is_error == 1, 'text-muted' : scanLog.is_error == 0}"><i
                                class="mdi mdi-circle-medium mr-2"></i>({{scanLog.created_at | date :'fullTime'}})
                            {{scanLog.logs}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

    <mat-dialog-actions align="end">

        <div *ngIf="data.detailmode === true" class="w-100 px-2">
            <button mat-flat-button mat-dialog-close class="btn-main"> Close</button>
        </div>

    </mat-dialog-actions>


</div>