import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './views/pages/login/login.component';
import { AuthguardGuard } from './services/authguard.guard';
import { ResetPasswordComponent } from './views/pages/reset-password/reset-password.component';
import { NewPasswordComponent } from './views/pages/new-password/new-password.component';
import { DataResolverService } from './services/data-resolver.service';
import { MenuDataResolverService } from './services/menu-data-resolver.service';
import { LayoutShtiksComponent } from './containers/default-layout/layout-shtiks/layout-shtiks.component';
import { ForgotPasswordComponent } from './views/pages/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "",
    // component: DefaultLayoutComponent,
    component: LayoutShtiksComponent,
    data: {
      title: "Home",
    },
    canActivate: [AuthguardGuard],
    resolve: {
      data: DataResolverService,
      menu: MenuDataResolverService,
    },
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },

      {
        path: "pages",
        loadChildren: () =>
          import("./views/pages/pages.module").then((m) => m.PagesModule),
      },

      // Account Management
      {
        path: "account-management/company-account",
        loadChildren: () =>
          import(
            "./pages/account-management/company-account/company-account.module"
          ).then((m) => m.CompanyAccountModule),
      },
      {
        path: "company-profile",
        loadChildren: () =>
          import(
            "./pages/account-management/company-account/company-account.module"
          ).then((m) => m.CompanyAccountModule),
      },
      {
        path: "account-management/user-account",
        loadChildren: () =>
          import(
            "./pages/account-management/user-account/user-account.module"
          ).then((m) => m.UserAccountModule),
        // canActivate: [AuthguardGuard]
      },

      //CLIENT
      {
        path: "inbound/purchase-request",
        loadChildren: () =>
          import(
            "./pages/inbound/purchase-request/purchase-request.module"
          ).then((m) => m.PurchaseRequestModule),
      },
      {
        path: "inbound/purchase-order",
        loadChildren: () =>
          import("./pages/inbound/purchase-order/purchase-order.module").then(
            (m) => m.PurchaseOrderModule
          ),
      },
      {
        path: "inbound/asn",
        loadChildren: () =>
          import("./pages/inbound/asn/asn.module").then((m) => m.ASNModule),
      },
      {
        path: "kitting/kit-orders",
        loadChildren: () =>
          import("./pages/kitting/kit-orders/kit-orders.module").then(
            (m) => m.KitOrdersModule
          ),
      },
      {
        path: "inventory/overview",
        loadChildren: () =>
          import(
            "./pages/inventory/inventory-overview/inventory-overview.module"
          ).then((m) => m.inventoryOverviewModule),
      },
      {
        path: "inventory/stock-transfer",
        loadChildren: () =>
          import("./pages/inventory/stock-transfer/stock-transfer.module").then(
            (m) => m.stockTransferModule
          ),
      },
      {
        path: "inventory/inventory-adjustments",
        loadChildren: () =>
          import(
            "./pages/inventory/inventory-adjustments/inventory-adjustments.module"
          ).then((m) => m.InventoryAdjustmentsModule),
      },
      {
        path: "inventory/cycle-counts",
        loadChildren: () =>
          import("./pages/inventory/cycle-counts/cycle-counts.module").then(
            (m) => m.CycleCountsModule
          ),
      },
      {
        path: "receiving/receive",
        loadChildren: () =>
          import("./pages/receiving/receive/receive.module").then(
            (m) => m.ReceiveModule
          ),
      },
      {
        path: "receiving/unload-receive",
        loadChildren: () =>
          import("./pages/receiving/unloadAndReceive/receive.module").then(
            (m) => m.ReceiveModule
          ),
      },
      {
        path: "receiving/blind-receiving",
        loadChildren: () =>
          import(
            "./pages/receiving/blind-receiving/blind-receiving.module"
          ).then((m) => m.BlindReceivingModule),
      },
      {
        path: "receiving/unload",
        loadChildren: () =>
          import("./pages/receiving/unload/unload.module").then(
            (m) => m.UnloadModule
          ),
      },
      {
        path: "outbound/sales-requests",
        loadChildren: () =>
          import("./pages/outbound/sales-request/sales-request.module").then(
            (m) => m.SalesRequestModule
          ),
      },
      {
        path: "outbound/sales-orders",
        loadChildren: () =>
          import("./pages/outbound/sales-orders/sales-orders.module").then(
            (m) => m.SalesOrdersModule
          ),
        //  canActivate: [AuthguardGuard]
      },
      {
        path: 'outbound/outbound-shipment',
        loadChildren: () =>
          import('./pages/outbound/outbound-shipment/outbound-shipment.module').then(
            (m) => m.OutboundShipmentModule
          ),
        //  canActivate: [AuthguardGuard]
      },
      {
        path: 'fulfillment/fulfillment-requests',
        loadChildren: () =>
          import(
            "./pages/fulfillment/fulfillmentRequests/fulfillment-requests.module"
          ).then((m) => m.FulfillmentRequestsModule),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "fulfillment/pick",
        loadChildren: () =>
          import("./pages/fulfillment/pick/pick.module").then(
            (m) => m.PickModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "fulfillment/pack",
        loadChildren: () =>
          import("./pages/fulfillment/pack/pack.module").then(
            (m) => m.PackModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "operations/overview",
        loadChildren: () =>
          import("./pages/tasks/overview/overview.module").then(
            (m) => m.OverviewModule
          ),
      },
      {
        path: "operations/tasks",
        loadChildren: () =>
          import("./pages/tasks/detail/tasks.module").then(
            (m) => m.TasksModule
          ),
      },
      {
        path: "operations/jobs",
        loadChildren: () =>
          import("./pages/tasks/jobs/jobs.module").then((m) => m.JobsModule),
      },
      {
        path: "tasks/execution",
        loadChildren: () =>
          import("./pages/tasks/execution/execution.module").then(
            (m) => m.ExecutionModule
          ),
      },
      {
        path: "setup/product-catalog",
        loadChildren: () =>
          import("./pages/setup/product-catalog/product-catalog.module").then(
            (m) => m.ProductCatalogModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/customers",
        loadChildren: () =>
          import("./pages/setup/customers/customers.module").then(
            (m) => m.CustomersModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/clients",
        loadChildren: () =>
          import("./pages/setup/clients/clients.module").then(
            (m) => m.ClientsModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/users",
        loadChildren: () =>
          import("./pages/setup/users/users.module").then((m) => m.UsersModule),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/suppliers",
        loadChildren: () =>
          import("./pages/setup/vendors/vendors.module").then(
            (m) => m.VendorsModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/boxes",
        loadChildren: () =>
          import("./pages/setup/boxes/boxes.module").then((m) => m.BoxesModule),
      },
      {
        path: "setup/printers-settings",
        loadChildren: () =>
          import(
            "./pages/setup/printers-settings/printers-settings.module"
          ).then((m) => m.PrintersSettingsModule),
      },
      {
        path: "setup/warehouse",
        loadChildren: () =>
          import("./pages/setup/warehouse/warehouse.module").then(
            (m) => m.WarehouseModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/parent-area",
        loadChildren: () =>
          import("./pages/setup/parent-area/parent-area.module").then(
            (m) => m.ParentAreaModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/areas",
        loadChildren: () =>
          import("./pages/setup/areas/areas.module").then((m) => m.AreasModule),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/location",
        loadChildren: () =>
          import("./pages/setup/location/location.module").then(
            (m) => m.LocationModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/integrations",
        loadChildren: () =>
          import(
            "./pages/setup/channel-configurations/channel-configurations.module"
          ).then((m) => m.ChannelConfigurationsModule),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/roles",
        loadChildren: () =>
          import("./pages/setup/user-roles/user-roles.module").then(
            (m) => m.UserRolesModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/lookup",
        loadChildren: () =>
          import("./pages/setup/lookup/lookup.module").then(
            (m) => m.LookupModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/tasktypes",
        loadChildren: () =>
          import("./pages/setup/task-types/task-types.module").then(
            (m) => m.TaskTypesModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/workflow",
        loadChildren: () =>
          import("./pages/setup/workflows/workflows.module").then(
            (m) => m.WorkflowModule
          ),
        // canActivate: [AuthguardGuard]
      },
      {
        path: "setup/automation-rules",
        loadChildren: () =>
          import("./pages/setup/automation-rules/AutomationRules.module").then(
            (m) => m.AutomationRulesModule
          ),
        // canActivate: [AuthguardGuard]
      },
    ],
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password",
    },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    data: {
      title: "Forgot Password",
    },
  },
  {
    path: "new-password",
    component: NewPasswordComponent,
    data: {
      title: "New Password",
    },
  },
  { path: "**", redirectTo: "dashboard" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  providers: [AuthguardGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
