import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { CustomSnackbarService } from '../custom-snackbar/custom-snackbar.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-grid-columnfilter',
  templateUrl: './grid-columnfilter.component.html',
  styleUrls: ['./grid-columnfilter.component.scss']
})
export class GridColumnfilterComponent implements OnInit {

  @Output('RefreshGridColumn') refreshGridColumn = new EventEmitter<any>();
  
  selection: any;
  master_columns:any[] = [];

  constructor(
    public service: ConfigService,
    public dialogRef: MatDialogRef<GridColumnfilterComponent>,
    public snackbarService: CustomSnackbarService,
    public alert: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any = []) { }

  ngOnInit(): void {
    this.selection = new SelectionModel(true, []);
    this.master_columns = this.data.data;
  }


  rowToogle(event: any, row: any) 
  {

    let rowInd = this.master_columns.findIndex(x => x.displayName == row.displayName);
    
    if(rowInd !== -1)
    {
      this.master_columns[rowInd].isChanged = true;
    }
  }

  submit()
  {
    this.dialogRef.close(this.master_columns.filter(x => x.isVisible));
  }

  clear(){
    this.dialogRef.close();
  }

}
