import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';

export type Action = 'Entered' | 'Selected' | 'Scanned';
export type Status = 'Valid' | 'Invalid';

export interface Log {
  task_id: number | string;
  sub_task_id: number | string;
  timestamp: string;
  event: string;
  user: string;
  user_id: number | null;
  action: Action | null;
  field: string;
  status: Status | null | undefined;
  reason: string | null;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class ScanLogsService {
  private snackbarSubject = new Subject<{ data: any }>();
  private orderChangeSubject = new Subject<{ dataArray: any }>();
  public scanlogOrderChange = this.orderChangeSubject.asObservable();
  public scanlogState = this.snackbarSubject.asObservable();
  private log: Log;

  constructor(private service: ConfigService) {
    this.log = {
      task_id: '',
      sub_task_id: '',
      timestamp: new Date().toISOString(),
      event: '',
      user: this.service.profileDetail.name,
      user_id: this.service.profileDetail.user_id,
      action: null,
      field: '',
      status: null,
      reason: null,
      value: '',
    };
  }

  public get currentLog(): Log {
    return this.log;
  }

  // Method to update the log
  public updateLog(partialLog: Partial<Log>): void {
    this.log = { ...this.log, ...partialLog };
  }

  // Build payload method
  private buildPayload(): Log {
    return this.log;
  }

  async saveLogs() {
    const payload = this.buildPayload();
    let saveLogsApi = '/api/save-logs';
    console.log("PAYLOAD: ", payload)
    const response: any = await this.service.postApi(
      saveLogsApi,
      payload,
      '',
      'wms_service'
    );
  }

  orderChanged(dataArray: any) {
    this.orderChangeSubject.next({
      dataArray: dataArray,
    });
  }
}
