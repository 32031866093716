import {  Injectable } from "@angular/core";
import { AppConstants } from "./app-constants";

@Injectable() export class BreadCrumbService {

 private menuNameIndex: any;
 private menuLinkIndex: any;
 private menuName: any;
 private menuLink: any;
 private breadCrumb: any;

 constructor() {}

 setMenuNameIndex(index: number) {
  this.menuNameIndex = index;
  this.menuLinkIndex = 0;
 }
 setMenuLinkIndex(index: number) {
  this.menuLinkIndex = index
 }

 getBreadCrumb() {
  this.menuName = AppConstants.APP_MENU_BUTTONS[this.menuNameIndex].name;
  this.breadCrumb = this.menuName + ' ' + this.menuLinkIndex;
 //  console.log('Breadcrumb' , this.breadCrumb);
 }
}
