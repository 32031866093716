import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from '../../../services/config.service';
import { AlertService } from '../alert/alert.service';

export interface DialogData {
  attachments: any;
  detailmode: any;
  title: any;
  single: any;
}
@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {
  attachment: any[] = [];

  hoveredImage: any;

  constructor(
    public service: ConfigService,
    public dialogRef: MatDialogRef<AttachmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private alert: AlertService
  ) {
    if (this.data.attachments) {
      this.attachment = this.data.attachments;
    }
  }

  upload(file: any): boolean {
    //console.log("Testing",file);

    const allowedFilesTypes = ['image/jpeg', 'image/png'];
    const maxFileSize = 1000000; // 2000000byte = 2MB

    // if file is greater than 1 Mb
    if (!allowedFilesTypes.includes(file.type)) {
      this.alert.show('This file type is not allowed!', 'error');
      return false;
    } else if (file.size > maxFileSize) {
      // if file type is not match with the allowed file types.
      this.alert.show('The attachment size should be of 1MB', 'error');
      return false;
    }
    // if both of the above conditions failed then this code will run.
    // You are allowed to upload this file
    Object.assign(file, { fileId: Date.now() });

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      file.file_url = reader.result;
      if (this.data.single) {
        console.log('1');
        this.attachment = [file];
      } else {
        console.log('2');
        this.attachment.push(file);
      }
    };

    //console.log(file);

    // this.attachment.forEach(item => {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = (_event) => {
    //     this.attachment[this.attachment.length - 1].file_url = reader.result;
    //   }
    // });

    return true;
  }

  uploadDataToForm() {
    this.dialogRef.close(this.attachment);
    console.log('this.attachment', this.attachment);
  }

  async deleteAttachment(item: any) {
    if (item.hasOwnProperty('id')) {
      const reponse: any = await this.service.deleteApi(
        '/api/attachments/' + item.id
      );
      if (reponse.status == 200 && reponse.success) {
        this.attachment.forEach((e: any, index: any) => {
          if (e.id == item.id) {
            this.attachment.splice(index, 1);
          }
        });
      }
    } else {
      this.attachment = this.attachment.filter((file: any) => {
        return file.fileId !== item.fileId;
      });
    }
  }

  ngOnInit(): void {
    //  console.log(this.data.attachments);
  }

  // ngOnDestroy(): void {
  //   //  console.log(this.attachment);
  //   this.dialogRef.close();
  // }

  imgHover(e: any, id: any) {
    this.hoveredImage = id;
  }
}
