import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfigService } from 'src/app/services/config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import { MatTable } from '@angular/material/table';
import { constants } from '../../../../constants';
import { Subject } from 'rxjs';
import { debounce } from 'lodash';
import { LinesItemsComponent } from '../line-items/line-items.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { E } from '@angular/cdk/keycodes';

export interface PeriodicElement {
  id: any;
  idForDelete: any;
  isLocalProduct?: any;
  po_no: any;
  ord_no: any;
  purchase_order_id: any;
  proposed_cost: any; //Sales Request
  product_id?: any;
  featured_image: any;
  product_name: any;
  product_sku: any;
  quantity: any;
  cost: any;
  uom_id: any;
  uom_name: any;
  base_unit: any;
  brand: any;
  category: any;
  requested_delivery_date: any;

  vendor_id: any;
  vendor_name: any;

  warehouse_id: any;
  warehouse_name: any;

  inventory_available_quantity: any; //salesOrder
  fulfillment_quantity: any; //salesOrder

  notes: any;
  attachments: any;
  requested_delivery_date_Display: Date;

  on_hand_qty: any;
  no_of_locations: any;
  availbleQty: any;
  from_id: any;
  qty: any;
  from: any;
  to: any;
  upc: any;
  no_of_components: any;
}

export interface paginationData {
  current_page: any; //current_PageNo
  last_page: any; // page_Count
  per_page: any; // page_Size
  total: any;
  to: any;
  from: any;
}

@Component({
  selector: 'app-add-product',
  templateUrl: './add-productpopup.component.html',
  styleUrls: ['./add-productpopup.component.scss'],
})
export class AddProductpopupComponent implements OnInit {
  @ViewChild(MatTable) table!: MatTable<PeriodicElement>;
  @ViewChild('inputProductRef') searchInputRef!: ElementRef;
  @ViewChild('prodListItems') ProductItemsGrid: LinesItemsComponent;

  @Output('ReturnDataToParent') returnDataToParent = new EventEmitter<any>();

  value: any;

  columnsToDisplay = [
    {
      displayName: 'Kit Name',
      field: 'name',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'SKU',
      field: 'name',
      clickable: false,
      fieldprd_sku: 'sku',
      fieldprd_img: 'attachment',
      columnWidth: '30%',
      isHiglighed: false,
      isVisible: true,
      isDragable: false,
      align: 'text-left',
    },
    {
      displayName: 'SKU Qty',
      field: 'quantity',
      isVisible: true,
      isDragable: false,
      isEditable: true,
      align: 'text-center',
      columnWidth: '10%',
    },
    {
      displayName: 'SKU',
      field: 'sku',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'UPC',
      field: 'upc',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'UOM',
      field: 'uom',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
      type: 'dropdown',
    },
    {
      displayName: 'SKU Cost',
      field: 'list_price',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Location',
      field: 'location_name',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Available',
      field: 'available',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'No. of Components',
      field: 'no_of_components',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Available Qty',
      field: 'available_qty',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Component Name',
      field: 'name',
      clickable: false,
      fieldprd_sku: 'sku',
      fieldprd_img: 'attachment',
      columnWidth: '20%',
      isHiglighed: false,
      isVisible: true,
      isDragable: false,
      align: 'text-left',
    },
    {
      displayName: 'UPC',
      field: 'upc',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Base Unit',
      field: 'uom',
      isVisible: true,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
      type: 'dropdown',
    },
    {
      displayName: 'Qty',
      field: 'quantity',
      isVisible: true,
      isDragable: false,
      isEditable: true,
      align: 'text-center',
      columnWidth: '10%',
    },
    {
      displayName: 'SKU',
      field: 'product_sku',
      clickable: false,
      fieldprd_sku: 'sku',
      fieldprd_img: 'attachment',
      columnWidth: '30%',
      isHiglighed: false,
      isVisible: true,
      isDragable: false,
      align: 'text-left',
    },
    {
      displayName: 'Item Description',
      field: 'product_name',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'No. of Locations',
      field: 'no_of_locations',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },
    {
      displayName: 'Qty on hand',
      field: 'on_hand_qty',
      isVisible: false,
      isDragable: false,
      align: 'text-center',
      columnWidth: '19%',
    },

    // { "displayName": "Product Cost", "field": "site_cost", isVisible: true, isDragable: false, align: "text-center", columnWidth: '19%' },
  ];

  gridTabs = [];

  gridDataSource: any;
  paginationDataSource = <paginationData>{};
  selectedIndex = 0;
  NoOfEntries: any = 15;
  apiURL = constants.productsAPI;
  selectedRecord: any;
  selectedRecordStatus: any = [];
  selectedRecordID: any = [];

  selection = new SelectionModel<PeriodicElement>(true, []);

  search_products_array_model: any;
  client_Id: any;
  moduleName: any;
  warehouse_Id: any;
  search_products_array: any = [];
  filtered_search_products_array: any = [];
  showAutocompletepro: boolean = false;
  products_array: PeriodicElement[] = [];
  fromLocationList: any;
  module: any;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      vendorIdForClientAsn: string;
      warehouseID: string;
      selectedProducts: any;
      client_id: any;
      moduleName: any;
      warehouse_Id: any;
    },
    public dialogRef: MatDialogRef<AddProductpopupComponent>,
    private service: ConfigService,
    public snackbarService: CustomSnackbarService,
    public alert: AlertService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.debounceProduct = debounce(this.debounceProduct, 2000);
    this.client_Id = this.data.client_id;
    this.warehouse_Id = this.data.warehouse_Id;
    this.moduleName = this.data.moduleName;
  }

  clearSelection: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    if (this.moduleName == 'Stock') {
      this.columnsToDisplay[0].isVisible = false;
      this.columnsToDisplay[2].isVisible = false;
      this.columnsToDisplay[3].isVisible = false;
      this.columnsToDisplay[4].isVisible = true;
      this.columnsToDisplay[5].isVisible = true;
      this.columnsToDisplay[6].isVisible = false;
      this.columnsToDisplay[7].isVisible = false;
      this.columnsToDisplay[8].isVisible = false;
      this.columnsToDisplay[11].isVisible = false;
      this.columnsToDisplay[12].isVisible = false;
      this.columnsToDisplay[13].isVisible = false;
      this.columnsToDisplay[14].isVisible = false;
      this.columnsToDisplay[15].isVisible = false;
      this.columnsToDisplay[16].isVisible = false;
      this.columnsToDisplay[17].isVisible = false;
      this.columnsToDisplay[18].isVisible = false;
    } else if (this.moduleName == 'kitOrders') {
      this.columnsToDisplay[1].isVisible = false;
      this.columnsToDisplay[2].isVisible = false;
      this.columnsToDisplay[3].isVisible = false;
      this.columnsToDisplay[5].isVisible = false;
      this.columnsToDisplay[6].isVisible = false;
      this.columnsToDisplay[7].isVisible = false;
      this.columnsToDisplay[9].isVisible = true;
      this.columnsToDisplay[10].isVisible = true;
      this.columnsToDisplay[11].isVisible = false;
      this.columnsToDisplay[12].isVisible = false;
      this.columnsToDisplay[13].isVisible = false;
      this.columnsToDisplay[14].isVisible = false;
      this.columnsToDisplay[15].isVisible = false;
      this.columnsToDisplay[16].isVisible = false;
      this.columnsToDisplay[17].isVisible = false;
      this.columnsToDisplay[18].isVisible = false;
    } else if (this.moduleName == 'SO') {
      this.columnsToDisplay[0].isVisible = false;
      this.columnsToDisplay[11].isVisible = false;
      this.columnsToDisplay[12].isVisible = false;
      this.columnsToDisplay[13].isVisible = false;
      this.columnsToDisplay[14].isVisible = false;
      this.columnsToDisplay[15].isVisible = false;
      this.columnsToDisplay[16].isVisible = false;
      this.columnsToDisplay[17].isVisible = false;
      this.columnsToDisplay[18].isVisible = false;
    } else if (this.moduleName == 'assembly') {
      this.columnsToDisplay[0].isVisible = false;
      this.columnsToDisplay[1].isVisible = false;
      this.columnsToDisplay[2].isVisible = false;
      this.columnsToDisplay[3].isVisible = false;
      this.columnsToDisplay[4].isVisible = false;
      this.columnsToDisplay[5].isVisible = false;
      this.columnsToDisplay[6].isVisible = false;
      this.columnsToDisplay[7].isVisible = false;
      this.columnsToDisplay[8].isVisible = false;
      this.columnsToDisplay[9].isVisible = false;
      this.columnsToDisplay[10].isVisible = false;
      this.columnsToDisplay[11].isVisible = true;
      this.columnsToDisplay[12].isVisible = true;
      this.columnsToDisplay[13].isVisible = true;
      this.columnsToDisplay[14].isVisible = true;
      this.columnsToDisplay[15].isVisible = false;
      this.columnsToDisplay[16].isVisible = false;
      this.columnsToDisplay[17].isVisible = false;
      this.columnsToDisplay[18].isVisible = false;
    } else if (this.moduleName == 'cycleCount') {
      this.columnsToDisplay[0].isVisible = false;
      this.columnsToDisplay[1].isVisible = false;
      this.columnsToDisplay[2].isVisible = false;
      this.columnsToDisplay[3].isVisible = false;
      this.columnsToDisplay[4].isVisible = false;
      this.columnsToDisplay[5].isVisible = false;
      this.columnsToDisplay[6].isVisible = false;
      this.columnsToDisplay[7].isVisible = false;
      this.columnsToDisplay[8].isVisible = false;
      this.columnsToDisplay[9].isVisible = false;
      this.columnsToDisplay[10].isVisible = false;
      this.columnsToDisplay[11].isVisible = false;
      this.columnsToDisplay[12].isVisible = false;
      this.columnsToDisplay[13].isVisible = false;
      this.columnsToDisplay[14].isVisible = false;
      this.columnsToDisplay[15].isVisible = true;
      this.columnsToDisplay[16].isVisible = true;
      this.columnsToDisplay[17].isVisible = true;
      this.columnsToDisplay[18].isVisible = true;
    } else {
      this.columnsToDisplay[0].isVisible = false;
      this.columnsToDisplay[11].isVisible = false;
      this.columnsToDisplay[12].isVisible = false;
      this.columnsToDisplay[13].isVisible = false;
      this.columnsToDisplay[14].isVisible = false;
      this.columnsToDisplay[15].isVisible = false;
      this.columnsToDisplay[16].isVisible = false;
      this.columnsToDisplay[17].isVisible = false;
      this.columnsToDisplay[18].isVisible = false;
    }
  }

  /**---------------grid check box functionality-------------- */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.gridDataSource?.length;
    return numSelected === numRows;
  }

  masterToggle(isAllSelected: boolean) {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.gridDataSource);
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  /**------------close dialog and return data to the form--------------- */
  closeDialog(data: any) {
    //  console.log('close-dialog', data);
    this.dialogRef.close(data);
  }

  /**--------------------Add product to the ASN form---------------- */
  async addproduct(action?: string) {
    let prodArr: any[] = [];

    await this.products_array.forEach((prod: any) => {
      let productObject: PeriodicElement = {
        on_hand_qty: prod?.available ? prod?.available : prod?.on_hand_qty,
        no_of_locations: prod?.no_of_locations,
        availbleQty: prod?.available_qty,
        qty: prod?.quantity == undefined ? '' : prod?.quantity,
        no_of_components: prod.no_of_components,
        upc: prod.upc,
        from: prod?.location_name,
        to: '',
        from_id: prod?.location_id,
        idForDelete: '',
        isLocalProduct: true,
        id: prod?.id,
        brand: prod?.brand_name,
        category: prod?.category_name,
        featured_image:
          prod?.featured_image == undefined
            ? prod.attachment
            : prod.featured_image,
        product_name: prod?.name ? prod?.name : prod?.product_name,
        product_sku: prod?.sku ? prod?.sku : prod?.product_sku,
        quantity: prod?.quantity == undefined ? '' : prod?.quantity,
        cost: prod?.list_price,
        // cost: prod?.site_cost,
        requested_delivery_date: new Date(),
        requested_delivery_date_Display: new Date(),

        po_no: '',
        ord_no: '',
        purchase_order_id: '',
        proposed_cost: '',
        product_id: prod.product_id ? prod.product_id : prod.id,
        uom_id: prod?.uom_id,
        uom_name: prod.uom ? prod.uom : '',
        base_unit: prod?.base_unit,

        vendor_id: '',
        vendor_name: '',

        warehouse_id: null,
        warehouse_name: [],

        inventory_available_quantity: '',
        fulfillment_quantity: '',

        notes: '',
        attachments: [],
      };
      prodArr.push(productObject);
    });

    if (action == 'AddNCont') {
      this.ProductItemsGrid.selection.clear();
      this.resetGridData();
      this.gridDataSource = this.search_products_array;

      this.service.setProducts(prodArr);
      // temporary solution for disable button add selected
      this.products_array.length = 0;
    }

    if (action == 'AddNClose') {
      // this.getfromlocation()
      this.closeDialog(prodArr);
      console.log(prodArr, 'prodArr Taimour');
    }
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  debounceProduct(e: any) {
    if (e.target.value) {
      this.searchInputRef.nativeElement.blur();
      this.products_array = [];
      if (this.moduleName == 'Stock') {
        this.getInventories();
      } else if (this.moduleName == 'assembly') {
        this.getKitProducts();
      } else if (this.moduleName == 'kitOrders') {
        this.getKits();
      } else if (this.moduleName == 'cycleCount') {
        this.getCCProducts();
      } else {
        this.getProducts();
      }
      // this.searchInputRef.nativeElement.inputFocus()
    }
    // this.searchInputRef.nativeElement.focus()

    //console.log(this.inputFocus)
    // this.inputFocus.nativeElement.blur()
  }

  handleEmptySearch(e: any) {
    if (!e.target.value) {
      this.search_products_array = [];
      this.gridDataSource = '';
    }
  }

  disableBtn(e: any) {
    this.products_array = [];
  }

  searchProduct(val: any) {
    if (val) {
      this.products_array = [];
      if (this.moduleName == 'Stock') {
        this.getInventories();
      } else {
        this.getProducts();
      }
    }
  }

  /**---- Lookups calls START------ */
  async getInventories() {
    this.ngxLoader.start();

    let inventoryAPI =
      `/api/inventories/search-product?sku=` +
      this.search_products_array_model +
      '&pageSize=15';

    if (this.client_Id && this.service.accountTypeAPI === 'wms_service') {
      inventoryAPI =
        '/api/inventories/products?client_id=' +
        this.client_Id +
        '&sku=' +
        this.search_products_array_model +
        '&warehouse_id=' +
        this.warehouse_Id;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(inventoryAPI, '');

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;
      for (let index = 0; index < this.search_products_array.length; index++) {
        this.search_products_array[index]['sku'] =
          this.search_products_array[index]['product_sku'];
        this.search_products_array[index]['name'] =
          this.search_products_array[index]['product_name'];
      }
      //  console.log(this.search_products_array);

      this.initializeGridData(response);
    } else if (response.data.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
    } else {
      this.alert.show(response.error.message, 'error');
    }
    this.ngxLoader.stop();
  }

  /**---- Lookups calls START------ */
  async getKitProducts() {
    this.ngxLoader.start();
    let kitProductsAPI =
      `/api/kit-products/lookup?term=` +
      this.search_products_array_model +
      '&pageSize=15';

    if (this.client_Id && this.service.accountTypeAPI === 'wms_service') {
      kitProductsAPI =
        '/api/kit-products/lookup?client_id=' +
        this.client_Id +
        '&term=' +
        this.search_products_array_model;
      // '&warehouse_id=' +
      // this.warehouse_Id;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(kitProductsAPI, '');

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;
      console.log(this.search_products_array, 'me');

      this.initializeGridData(response);
    } else if (response.data.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
    } else {
      this.alert.show(response.error.message, 'error');
    }
    this.ngxLoader.stop();
  }

  /**---- Lookups calls START------ */
  async getProducts() {
    this.ngxLoader.start();
    if (
      this.moduleName == 'PO' ||
      this.moduleName == 'ASN' ||
      this.moduleName == 'ASN-N'
    ) {
      this.module = 'inbound';
    } else if (this.moduleName == 'SO') {
      this.module = 'outbound';
    }
    let productApi =
      `/api/products/lookup?term=` +
      this.search_products_array_model +
      `&pageSize=${this.NoOfEntries}` +
      `module=${this.module}`;

    if (this.client_Id && this.service.accountTypeAPI === 'wms_service') {
      productApi =
        '/api/products/lookup?term=' +
        this.search_products_array_model +
        '&client_id=' +
        this.client_Id +
        `&pageSize=${this.NoOfEntries}` +
        `&module=${this.module}`;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(productApi, '');

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;

      this.initializeGridData(response);
    } else if (response.data.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
    } else {
      this.alert.show(response.error.message, 'error');
    }
    this.ngxLoader.stop();
  }
  async getKits() {
    this.ngxLoader.start();

    let productApi =
      `/api/kits/lookup?term=` +
      this.search_products_array_model +
      `&pageSize=${this.NoOfEntries}`;

    if (
      this.client_Id &&
      this.warehouse_Id &&
      this.service.accountTypeAPI === 'wms_service'
    ) {
      productApi =
        '/api/kits/lookup?term=' +
        this.search_products_array_model +
        '&client_id=' +
        this.client_Id +
        '&warehouse_id=' +
        this.warehouse_Id +
        `&pageSize=${this.NoOfEntries}`;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(productApi, '');

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;
      //  console.log(response.data.data);

      this.initializeGridData(response);
    } else if (response.data.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
    } else {
      this.alert.show(response.error.message, 'error');
    }
    this.ngxLoader.stop();
  }

  async getCCProducts() {
    this.ngxLoader.start();

    let productApi =
      `/api/cycle-count/inventory-data?sku=` +
      this.search_products_array_model +
      `&pageSize=${this.NoOfEntries}`;

    if (
      this.client_Id &&
      this.warehouse_Id &&
      this.service.accountTypeAPI === 'wms_service'
    ) {
      productApi =
        '/api/cycle-count/inventory-data?sku=' +
        this.search_products_array_model +
        '&client_id=' +
        this.client_Id +
        '&warehouse_id=' +
        this.warehouse_Id +
        `&pageSize=${this.NoOfEntries}`;
      // this.autoCompleteFilterClient();
    }
    const response: any = await this.service.getApi(productApi, '');

    if (response.status && response.data.data.length > 0) {
      this.search_products_array = response.data.data;
      //  console.log(response.data.data);

      this.initializeGridData(response);
    } else if (response.data.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
    } else {
      this.alert.show(response.error.message, 'error');
    }
    this.ngxLoader.stop();
  }

  getproductsToOrderList(e: any) {
    this.showAutocompletepro = false;

    //console.log(e);

    if (e?.id) {
      let productObject: PeriodicElement = {
        idForDelete: '',
        id: e.id,
        brand: e?.brand_name,
        category: e?.category_name,
        featured_image: e.featured_image,
        product_name: e.name,
        product_sku: e.sku,
        no_of_components: e.no_of_components,
        no_of_locations: e.no_of_locations,
        upc: e.upc,
        quantity: '',
        cost: e.site_cost,
        requested_delivery_date: new Date(),
        requested_delivery_date_Display: new Date(),

        po_no: '',
        ord_no: '',
        purchase_order_id: '',
        proposed_cost: '',
        product_id: e.product_id,
        uom_id: '',
        uom_name: '',
        base_unit: e.base_unit,

        vendor_id: '',
        vendor_name: '',

        warehouse_id: '',
        warehouse_name: '',
        availbleQty: e?.available_qty,

        inventory_available_quantity: '',
        fulfillment_quantity: '',

        notes: '',
        attachments: [],

        on_hand_qty: '',
        from_id: '',
        qty: '',
        from: '',
        to: '',
      };
      this.products_array.push(productObject);
      this.gridDataSource.push(productObject);
      this.table.renderRows();

      // this.search_products_array = this.products_array;
      //  this.showAutocompletepro = false;
      this.search_products_array_model = [];

      this.sendDataToParent();
    } else if (e?.length >= 1) {
      this.showAutocompletepro = true;
    }
    //else if (e?.length >= 3) {
    //   this.getProducts(e);
    //   this.showAutocompletepro = true;
    // } else {
    //   this.showAutocompletepro = false;
    // }
  }

  bindGridDataSource() {
    //console.log("Test", this.search_products_array);

    this.search_products_array.forEach((prod: any) => {
      let productObject: PeriodicElement = {
        idForDelete: '',
        id: prod?.id,
        featured_image: prod?.featured_image,
        brand: prod?.brand_name,
        category: prod?.category_name,
        product_name: prod?.name,
        no_of_components: prod?.no_of_components,
        no_of_locations: prod?.no_of_locations,
        upc: prod?.upc,
        product_sku: prod?.sku,
        availbleQty: prod?.available_qty,
        quantity: '',
        cost: prod?.site_cost,
        requested_delivery_date: new Date(),
        requested_delivery_date_Display: new Date(),

        po_no: '',
        ord_no: '',
        purchase_order_id: '',
        proposed_cost: '',
        product_id: prod?.product_id,
        uom_id: '',
        uom_name: '',
        base_unit: prod?.base_unit,

        vendor_id: '',
        vendor_name: '',

        warehouse_id: '',
        warehouse_name: '',

        inventory_available_quantity: '',
        fulfillment_quantity: '',

        notes: '',
        attachments: [],

        on_hand_qty: '',
        from_id: '',
        qty: '',
        from: '',
        to: '',
      };
      this.products_array.push(productObject);
      this.gridDataSource.push(productObject);
      this.table.renderRows();
    });
  }

  sendDataToParent() {
    //console.log(this.products_array);
    //this.returnDataToParent.emit(this.products_array);
  }

  async getGridData(
    status?: any,
    page?: any,
    noRecords: string = this.NoOfEntries
  ) {
    let url =
      this.apiURL +
      '?client_id=' +
      this.client_Id +
      '&term=' +
      this.search_products_array_model +
      (page ? '&page=' + page : '') +
      `&pageSize=${this.NoOfEntries}`;
    if (this.moduleName == 'Stock') {
      url =
        `/api/inventories/search-product?client_id=` +
        this.client_Id +
        '&sku=' +
        this.search_products_array_model +
        '&warehouse_id=' +
        this.warehouse_Id +
        (page ? '&page=' + page : '') +
        `&pageSize=${this.NoOfEntries}`;
    }

    const response: any = await this.service.getApi(url, {});

    if (response.status && response?.data?.data.length > 0) {
      this.search_products_array = response.data.data;
      if (this.moduleName == 'Stock') {
        for (
          let index = 0;
          index < this.search_products_array.length;
          index++
        ) {
          this.search_products_array[index]['sku'] =
            this.search_products_array[index]['product_sku'];
          this.search_products_array[index]['name'] =
            this.search_products_array[index]['product_name'];
        }
      }
      //  console.log('this.search_products_array', this.search_products_array)
      this.initializeGridData(response);
    } else if (response.status && response?.data?.data.length <= 0) {
      this.search_products_array = [];
      this.initializeGridData(response);
      // this.alert.show('No Data Record Found For Selected Criteria', 'error');
    } else {
      this.alert.show(response.error.message, 'error');
    }
  }

  initializeGridData(response: any) {
    let Responsedata = response?.data?.data;

    //replacing 0 with empty string
    Responsedata.map((x: any) => {
      if (x.quantity == 0) {
        x.quantity = '';
      }

      x.list_price = x.list_price ? '$ ' + x.list_price : '-';
      x.uom_id = x.base_unit_id;
    });

    this.gridDataSource = Responsedata;
    this.paginationDataSource = response?.data;
  }

  async resetGridData() {
    let prods: any[] = [];
    await this.search_products_array.forEach((item: any) => {
      item.quantity = '';
      prods.push(item);
    });

    this.gridDataSource = prods;
  }

  pageChanges(page: any) {
    //let status = this.gridTabs[this.selectedIndex].apicall;
    this.getGridData('', page);
  }

  pageSizeForList(pageSize: any) {
    this.NoOfEntries = pageSize;
    //let status = this.gridTabs[this.selectedIndex].apicall;
    this.getGridData('', '', this.NoOfEntries);
  }

  actionClick(e: any) {}

  selectedRow(e: any) {
    this.selectedRecordStatus = [];
    this.selectedRecordID = [];
    if (e.length > 0) {
      this.selectedRecord = true;
      this.products_array = e;
      e.forEach((prod: any) => {
        this.selectedRecordStatus.push(prod.status);
        this.selectedRecordID.push(prod.id);
      });
    } else {
      this.selectedRecord = false;
      this.selectedRecordStatus = [];
      this.selectedRecordID = [];
      this.products_array = [];
    }
  }

  allselectedRow(e: any) {
    if (e.length > 0) {
      this.products_array = [];
      e.forEach((prod: any) => {
        this.products_array.push(prod);
      });
    }
  }
}
