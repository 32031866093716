<!--<c-footer>-->
<div>
    <a href="https://coreui.io/pro/angular/" target="_blank">SHTIKS</a>
    <span> &copy; 2022 SHTIKS</span>
</div>
<div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
        <span> SHTIKS</span>
    </a>
</div>
<!--</c-footer>-->
