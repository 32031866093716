<div class="container-fluid">
  <div class="row justify-content-between border-bottom mb-3">
    <div class="col-9 pl-3">
      <h2 mat-dialog-title>{{data.title}}</h2>
    </div>

    <div class="col-3 pr-3 text-right">
      <mat-icon mat-dialog-close>clear</mat-icon>
    </div>
  </div>

  <mat-dialog-content class="mat-typography">
    <div *ngIf="!data.detailmode">
      <el-upload accept=".png,.jpg,.jpeg" class="upload-demo" [drag]="true" [multiple]="!data.single"
        [upload-filter]="upload.bind(this)">
        <ng-template #trigger>
          <img src="../../../../assets/images/upload.png" class="mt-5 mb-2" alt="" />
          <div class="el-upload__text">Drag and drop an image, or</div>
          <a>Browse File</a>
        </ng-template>
      </el-upload>

      <!-- <div *ngIf="attachment" class="d-flex">
        <div class="card" *ngFor="let item of attachment" style="margin-right: 10px">
          <p class="mb-0">{{ item.name? item.name : (item.file_name? item.file_name: '-') }}</p>
          <i *ngIf="item.name || item.file_name " [ngClass]="
              item ? 'el-icon-circle-check el-icon-upload-success' : 'd-none'
            "></i>
        </div>
      </div> -->
    </div>

    <div *ngIf="attachment.length > 0" class="attachment-grid">
      <div class="fix-width card" *ngFor="let item of attachment" 
        (mouseenter)="imgHover($event, 'img_' + item.id)" (mouseleave)="hoveredImage = undefined">
        <img class="w-100" [src]="item.file_url? item.file_url: ''" alt="attachment"
           />
        <!-- <i *ngIf="!data.detailmode" class="el-icon-upload-success el-icon-circle-check"></i> -->

        <button type="button" 
        [id]="'img_' + item.id" 
        *ngIf="hoveredImage == 'img_' + item.id && !data.detailmode"
        class="btn btn-primary btn-attachDelete"
        (click)="deleteAttachment(item)">
          <i [ngClass]="'icon-close'" style="cursor: pointer;" title="Delete"></i>
        </button>
        <!-- <button type="button" class="btn btn-primary">
          <i [ngClass]="'el-icon-delete'" *ngIf="hoveredImage == 'img_' + item.id" [id]="'img_' + item.id"
            style="font-weight: 800; cursor: pointer;" title="Delete"></i>
        </button> -->
        <!-- <span *ngIf="!data.detailmode" (click)="deleteAttachment(item)">
          <i [ngClass]="'el-icon-delete'" *ngIf="hoveredImage == 'img_' + item.id" [id]="'img_' + item.id"
            style="font-weight: 800; cursor: pointer;" title="Delete"></i>
        </span> -->

        <!-- <div class="card-body d-flex justify-content-between align-itmes-center">
          <label class="f-s-14 primary">
            {{ item.file_name? item.file_name : (item.name? item.name: '-')}}
          </label>
          <span *ngIf="!data.detailmode" (click)="deleteAttachment(item)">
            <i [ngClass]="'el-icon-delete'" style="font-weight: 800; cursor: pointer;" title="Delete"></i>
          </span>
        </div> -->
      </div>
    </div>

    <div class="col-6 mb-3" *ngIf="data.attachments?.length === 0 && data.detailmode">
      <p class="f-s-14 primary mt-3 pl-2">No attachment available.</p>
    </div>

  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <div class="d-flex justify-content-end align-items-center w-100">
        <button mat-flat-button class="btn-outlined" (click)="closeDialog()">Cancel</button>
        <button mat-flat-button class="btn-main" (click)="upload()" [disabled]="!true">Upload File</button>
    </div>
  </mat-dialog-actions> -->

  <mat-dialog-actions>
    <div class="d-flex justify-content-end w-100 align-items-center mt-2">
      <div class="mx-2">
        <button mat-flat-button mat-dialog-close class="btn-outlined">
          Close
        </button>
        <button *ngIf="!data.detailmode" mat-flat-button (click)="uploadDataToForm()" class="btn-main">
          Upload File
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>