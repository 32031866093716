import { KeyValue } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { audit, map, Subscription } from 'rxjs';
import { HistoryService } from './history.service';
import { NotesComponent } from '../notes/notes.component';
import { MatDialog } from '@angular/material/dialog';
import { ScanLogsComponent } from '../scan-logs/scan-logs.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { AlertService } from '../alert/alert.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ScanLogsService } from '../scan-logs/scan-logs.service';

interface HistroyObject {
  [key: string]: {
    id: number,
    user_type: string,
    new_values: { status: string, po_no: number },
    updated_at: string,
    created_at: string,
    event: string,
    auditable_type: string,
    tags: string,
    details: any[],
    task_type: string,
    module_number: any,
    user_name: string,
  }[];
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  show: boolean = false;
  historyArray: any;
  scanLogs: any;
  historyTitle: any;
  historySubscription: Subscription;
  currentUrl: string;
  selectedOption: string = 'Newest to oldest';
  scanlogsOrderChangeSub: any;
  scanlogsSort: string = 'desc';
  showScanlogs: boolean | undefined;

  historyClose() {
    this.selectedOption = 'Newest to oldest';
    this.historyService.close();
  }
  backdropClick(){
    this.selectedOption = 'Newest to oldest';
    this.show = false;
  }

  constructor(private historyService: HistoryService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, public service: ConfigService, public alert: AlertService, private NgxLoader: NgxUiLoaderService, private ScanLogsService: ScanLogsService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== this.currentUrl) {
          this.historyClose();
        }
        this.currentUrl = event.url;
      }
    });
  }

  ngOnDestroy(): void {
    //  throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.historySubscription = this.historyService.historyState
      .subscribe({
        next: (state) => {
          this.show = state.show;
          this.historyArray = state.audits; //temporary
          this.historyTitle = state.title;
          this.showScanlogs = state.showLogs;
          // this.selectedOption = 'Newest to oldest';
          // this.historyArray = Object.keys(state.audits.date).map(key=> ({ value:state.audits.date[key], key }) );
          // if (state.audits.scan_logs) {
          //   this.scanLogs = state.audits.scan_logs;
          // }
        }
      })

    this.scanlogsOrderChangeSub = this.ScanLogsService.scanlogOrderChange
      .subscribe({
        next: (state) => {
          this.scanlogsSort = state.dataArray; //temporary
          this.getScanlogs();
        }
    })

  }
  

  public trackItem(index: number, item: any) {
    return item.trackId;
  }

  insertSpaces(text: string) {
    text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    text = text.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return text;
  }

  sortByDate(): number {
    return 0;
  }

  changeOrder(event: Event) {
    const selectedValue = this.selectedOption === 'Newest to oldest' ? 'desc' : 'asc';
    
    this.historyService.orderChanged(selectedValue);
  }

  formatTaskId(task_id?: number): string {
    return task_id ? 'TI-' + task_id.toString().padStart(6, '0') : '';
  }

  async getScanlogs() {
    this.scanLogs = [];
    let scanlogApi = `/api/get-scan-logs`;
    const response: any = await this.service.getApi(
      scanlogApi,
      { 
        "task_id": this.historyService.task_id,
        "sort_by": this.scanlogsSort || "desc"
      },
      {},
      'wms_service'
     );
     if (response.status && response?.data) {
      this.scanLogs = response.data;
    } else {
      this.alert.show('Scan Log Not Found.', 'error');
      return;
    }
  };

  async openScanLogs() {
    this.NgxLoader.start();
  
    try {
      await this.getScanlogs();
  
      this.NgxLoader.stop();
  
      if (this.scanLogs.scan_logs.length > 0) {
        this.historyService.close();
        const dialogRefNotes = this.dialog.open(ScanLogsComponent, {
          width: '900px',
          height: '500px',
          data: { scanLogs: this.scanLogs, task_id: this.historyService.task_id },
        });
  
        dialogRefNotes.beforeClosed().subscribe((data) => {
          console.log('closed');
        });
      } else {
        this.alert.show('Scan Log Not Found.', 'error');
      }
    } catch (error) {
      this.NgxLoader.stop();
      this.alert.show('Scan Log Not Found.', 'error');
    }
  };

  // In your component.ts file
getWorkflowClass() {
  if (this.historyArray?.workflow_type) {
    const wordCount = this.historyArray.workflow_type.split(' ').length;
    return wordCount ===  2? 'single-word' : 'multi-word';
  }
  return '';
}

}
