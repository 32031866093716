<div>
  <div
    class="d-flex justify-content-between align-items-center border-bottom mb-3 px-4 pb-2"
  >
    <h2 mat-dialog-title class="mb-0">Warning!</h2>
    <!-- <mat-icon mat-dialog-close>clear</mat-icon> -->
  </div>

  <mat-dialog-content
    class="mat-typography"
    style="padding-top: 15px; padding-bottom: 15px"
  >
    <div class="row mrgn-150">
      <p>{{data ? data : 'Are you sure to Delete this record?'}}</p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-end">
    <div class="px-4">
      <button mat-flat-button class="btn-outlined" (click)="closeDialog('0')">
        No
      </button>
      <button mat-flat-button class="mr-2 btn-main" (click)="closeDialog('1')">
        Yes
      </button>
    </div>
  </mat-dialog-actions>
</div>
