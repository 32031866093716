import { Component, OnInit } from '@angular/core';
import { Form } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { ConfigService } from 'src/app/services/config.service';

// import { SubjectsService } from 'src/app/services/subjects.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  hide = true;
  reenter = true;
  email : any = ''; //password
  password : any = ''; //password
  re_enter_password : any = ''; //password
  data: any = {};
  token: any;
  constructor(
    private route: Router, 
    public service: ConfigService,
    private router: ActivatedRoute,
    public snackbarService:CustomSnackbarService, 
    public alert: AlertService,
    private ngxLoader: NgxUiLoaderService) {

  }

  showPassword(input: any): any {
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  async resetpassword() {
    if (this.password == '') {
      this.alert.show('New Password Is Required', 'error');
      return;
    }
    if (this.re_enter_password == '') {
      this.alert.show('Re-Enter Password Is Required', 'error');
      return;
    }
    if (this.password !== this.re_enter_password) {
      this.alert.show('The Confirmed Password & New Password Are Not Matching', 'error');
      return;
    }
    this.email = this.service.email;
    this.data.password = this.password;
    this.data.password_confirmation = this.re_enter_password;
    this.ngxLoader.start();
    const data1: any = await this.service.postFormData(`/api/service_provider_password_set?token=${this.data.token ? this.data.token : ''}`, this.data, '', 'auth_service');
    if (data1.status === 200) {
      this.ngxLoader.stop();
      this.alert.show(data1.message, 'success');
      localStorage.removeItem('access_token');
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('warehouse_id');
      localStorage.removeItem('client_id');
      setInterval(() => {
        this.route.navigate(['login']);
      }, 3000)
      setInterval(() => {
        window.location.reload();
      }, 3200)
    }
    else {
      this.alert.show(data1.error.message, 'error');
    }
    
  }

  ngOnInit() {
    this.data.token = this.router.snapshot.queryParamMap.get('token');
    this.validateURL();
  }

  async validateURL(){
    const resp: any = await this.service.postFormData(`/api/token-check`, this.data, '', 'auth_service');
    if(!resp.success){
      this.alert.show("Link Expired", 'error');
      setInterval(() => {
        this.route.navigate(['login']);
      }, 2000);
    }
  }

}
