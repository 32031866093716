
<section class="vh-100">
    <div class="container-fluid overflow-hidden">
        <div class="row">

            <div class="col-sm-12 col-md-7 col-lg-6 col-xl-6 px-0 d-none d-sm-block position-relative">
                <img src="../../../../assets/images/Group 1836.png" alt="Login image" class="w-100 vh-100 position-relative">
                <img src="/assets/images/wize-logo.png" alt="Login image" class="logo">
                <p class="mb-0 warehouse">End to End Warehouse</p>
                <p class="mb-0 warehouse1">and Logistics.</p>
                <p class="mb-0 warehouse2">We guarantee your products from your</p>
                <p class="mb-0 warehouse3">supplier to your customer in record time</p>
                <p class="mb-0 warehouse4">with a 100% accuracy.</p>
                <p class="mb-0 rights">© 2022 Shtiks All Rights Reserved</p>
            </div>

            <div class="col-sm-12 col-md-5 col-lg-6 col-xl-6 text-black z-index-9">

                <div class="d-flex align-items-center h-custom-2 px-5 ms-xl-5 mt-0 pt-0 login-wrapper">

                    <form class="mb-5">

                        <div class="mb-200">

                            <div>
                                <h3 class="fw-bold mb-2 pb-2 f-s-36">Set new Password</h3>
                                <h6 class="fw-normal mb-1 pb-1 f-s-18 welcome">Setup your password for the account</h6>
                                <!-- <h6 class="fw-normal mb-4 pb-4 f-s-18 welcome">previously used passwords</h6> -->
                            </div>

                            <div class="form-outline mb-4">
                                <label class="form-label d-block f-s-16" for="form2Example18">Enter new password</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Enter new password</mat-label>
                                    <input matInput [type]="reenter ? 'password' : 'text'" [(ngModel)]="password" name="password">
                                    <button mat-icon-button matSuffix (click)="reenter = !reenter" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="reenter">
                                      <mat-icon>{{reenter ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                </mat-form-field>

                            </div>

                            <div class="form-outline mb-4">
                                <label class="form-label d-block f-s-16" for="form2Example28">Re-Enter new password</label>
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Re-Enter new password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="re_enter_password" name="re_enter_password">
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                                </mat-form-field>

                            </div>

                            <button class="btn btn-info btn-lg btn-block w-100 custom-btn mt-3" (click)="resetpassword()" type="button">Submit</button>
                        </div>

                    </form>

                </div>

            </div>

        </div>
    </div>
</section>
<app-custom-snackbar></app-custom-snackbar>
<app-alert></app-alert>
