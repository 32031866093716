import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHandler,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { Permissions } from './interfaces';
import { JsonPipe } from '@angular/common';
// import { Interceptor } from './interceptor';
let token = '';

type Services = 'auth_service' | 'wms_service'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private filterState: any = {};
  private chipListState: any[] = [];
  private currentPage: number = 0;

  private filteredDataSubject = new BehaviorSubject<boolean>(false);
  filteredData$ = this.filteredDataSubject.asObservable();
  filteredDataState: any;

  setFilterState(state: any, isFilter?:any) {
    this.filterState = state;
    if(!isFilter){
      this.filteredDataSubject.next(true); // Emit true when filter is applied
    }
  }


  getFilterState() {
    return this.filterState;
  }
  setFilteredDataState(state: any) {
    this.filteredDataState = state;
  }

  getFilteredDataState() {
    return this.filteredDataState;
  }

  parsePhoneNumber(rawPhone: string) {
    return rawPhone.includes("{") ? JSON.parse(rawPhone)?.e164Number : rawPhone;
  }

  clearFilterState() {
    this.filterState = {};
    this.filteredDataSubject.next(false); // Emit false when filters are cleared
  }

  setChipListState(chipList: any[]) {
    this.chipListState = chipList;
  }

  getChipListState() {
    return this.chipListState;
  }
  setCurrentPageIndex(index: number) {
    this.currentPage = index;
  }

  getCurrentPageIndex(): number {
    return this.currentPage;
  }
  setPayloadForFilter(payload: any) {
    this.PayloadForFilter = payload;
  }
  getPayloadForFilter() {
    return this.PayloadForFilter;
  }
  searchInputValueMain: any = '';
  PayloadForFilter: any = '';
  shtiksHeader: any;
  profileDetail: any = {
    profilepic: '',
    client_id: '',
    client_name: '',
    client_from: '',
    client_from_id: '',
    company_name: '',
    req_from_address: '',
    account_type: '',
    sub_client_id: '',
    warehouse_id: '',
    name: '',
    user_id: '',
    email: "",
    phone: "",
    timezone: "",
    id: "",
    check_service_Provider:""
  };
  accountTypeAPI: any;
  loading: boolean = false;

  //import_file: any;
  attachment: any;

  token: any;
  email: any;

  permissions: Permissions;

  user: any = {
    user: {
      user_dp: {
        created_at: '',
        name: '',
        size: 0,
        originalname: '',
        mimetype: '',
        path: '',
      },
      active: false,
      active_status: false,
      app_token: '',
      bio: '',
      location: '',
      country: '',
      created_at: '',
      dark_mode: false,
      deleted: false,
      device_token: '',
      email_verified: false,
      eula_agree: false,
      gender: '',
      login_type: '',
      messenger_color: '',
      remember_token: false,
      updated_at: '',
      verification_code: '',
      verification_time: '',
      dob: '',
      _id: '',
      username: '',
      fname: '',
      lname: '',
      mobile: '',
      email: '',
      connect: [],
      connected: [],
      createdAt: '',
      updatedAt: '',
      __v: 50,
    },
    token: '',
  };

  spRoles: any = [
    {
      user: 'wms_service',
      restrictedPages: ['purchase-request', 'sales-request'],
    },
    {
      user: 'client_service',
      restrictedPages: ['warehouse', 'clients', 'pick', 'pack', 'boxes'],
    },
  ];

  selectedIndexForGridTabs = 0;

  private selectedProductsAssign = new BehaviorSubject([]);
  private selectedLocationsArray = new BehaviorSubject({});
  selectedProducts = this.selectedProductsAssign.asObservable();
  selectedLocations = this.selectedLocationsArray.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  setPermissions() {
    if (
      localStorage.getItem('permissions') != 'undefined' &&
      localStorage.getItem('permissions') != 'null'
    ) {
      this.permissions = JSON.parse(
        localStorage.getItem('permissions') || '{}'
      );
    }
  }

  headerSetup(contentType: any) {
    const token = localStorage.getItem('access_token')
      ? localStorage.getItem('access_token')
      : '';
    const headerObj = {
      //'Content-Type': contentType,
      enctype: contentType,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers':
        'Origin, X-Requested-With, Content-Type, Accept',
      Authorization: 'Bearer ' + token,
    };

    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    return { headers: httpHeaders };
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.token;

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('access_token', authToken),
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }

  openSnackBar(message: any, action: any, duration: any) {
    this._snackBar.open(message, action, {
      duration: 2000,
      verticalPosition: 'top',
    });
  }

  settoken(tok: any) {
    token = tok;
  }

  setuser(usr: any) {
    this.user = usr;
  }

  getuser() {
    return this.user;
  }

  getProfileDetail() {
    return this.profileDetail;
  }

  setBaseUrl(value: any) {
    switch (value) {
      case 'auth_service':
        return environment.authServiceUrl;
      case 'client_service':
        return environment.clientServiceUrl;
      case 'wms_service':
        return environment.spServiceUrl;
      default:
        return environment.baseUrl;
    }
  }

  async postFormData(
    url: any,
    data: any,
    headerson?: any,
    anotherService?: any
  ) {
    // debugger
    const options = this.headerSetup('multipart/form-data;');
    let result: any;
    const uploadData: FormData = new FormData();
    for (var key in data) {
      if (
        !key.includes('Display') &&
        key != 'products' &&
        key != 'product_lines' &&
        key != 'packings' &&
        key != 'tags' &&
        key != 'tracking_type' &&
        key != 'product_attachments' &&
        key != 'kit_attachments' &&
        data[key]
      )
        uploadData.append(key, data[key]);
    }

    if (data.product_lines?.length > 0) {
      if (url.toLowerCase().includes('kits'.toLowerCase())) {
        data.product_lines.forEach(function (element: any, index: any) {
          uploadData.append('product_lines[' + index + '][id]', element.id);
          uploadData.append(
            'product_lines[' + index + '][product_sku]',
            element.product_sku
          );
          uploadData.append(
            'product_lines[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append(
            'product_lines[' + index + '][uom_id]',
            element.uom_id
          );
          uploadData.append('product_lines[' + index + '][cost]', element.cost);
          uploadData.append(
            'product_lines[' + index + '][brand]',
            element.brand
          );
          uploadData.append(
            'product_lines[' + index + '][category]',
            element.category
          );
          uploadData.append('product_lines[' + index + '][upc]', element.upc);
          // uploadData.append('product_lines[' + index + '][warehouse_id]', element.warehouse_id ? element.warehouse_id : '');
          // uploadData.append('product_lines[' + index + '][requested_delivery_date]',element.requested_delivery_date);
          // uploadData.append('product_lines[' + index + '][notes]', element.notes);
          // element.attachments?.forEach((element2: any, index2: any) => {
          //   uploadData.append('product_lines[' + index + '][attachments][' + index2 + ']',element2);
          // })
        });
      }
    }

    if (data.products?.length > 0) {
      if (url.toLowerCase().includes('purchaseRequests'.toLowerCase())) {
        data.products.forEach(function (element: any, index: any) {
          uploadData.append('products[' + index + '][id]', element.id);
          uploadData.append(
            'products[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append('products[' + index + '][uom_id]', element.uom_id);
          uploadData.append('products[' + index + '][cost]', element.cost);
          uploadData.append(
            'products[' + index + '][vendor_id]',
            element.vendor_id
          );
          uploadData.append(
            'products[' + index + '][requested_delivery_date]',
            element.requested_delivery_date
          );
          uploadData.append('products[' + index + '][notes]', element.notes);
          element.attachments?.forEach((element2: any, index2: any) => {
            uploadData.append(
              'products[' + index + '][attachments][' + index2 + ']',
              element2
            );
          });
        });
      }
      if (url.toLowerCase().includes('purchaseOrders'.toLowerCase())) {
        data.products.forEach(function (element: any, index: any) {
          uploadData.append('products[' + index + '][id]', element.id);
          uploadData.append(
            'products[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append('products[' + index + '][uom_id]', element.uom_id);
          uploadData.append('products[' + index + '][cost]', element.cost);
          uploadData.append(
            'products[' + index + '][warehouse_id]',
            element.warehouse_id ? element.warehouse_id : ''
          );
          uploadData.append(
            'products[' + index + '][requested_delivery_date]',
            element.requested_delivery_date
          );
          uploadData.append('products[' + index + '][notes]', element.notes);
          element.attachments?.forEach((element2: any, index2: any) => {
            uploadData.append(
              'products[' + index + '][attachments][' + index2 + ']',
              element2
            );
          });
        });
      }

      if (url.toLowerCase().includes('asn'.toLowerCase())) {
        data.products.forEach(function (element: any, index: any) {
          uploadData.append('products[' + index + '][id]', element.id);
          uploadData.append(
            'products[' + index + '][product_id]',
            element.product_id
          );
          uploadData.append(
            'products[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append('products[' + index + '][uom_id]', element.uom_id);
          uploadData.append('products[' + index + '][cost]', element.cost);
          uploadData.append(
            'products[' + index + '][purchase_order_id]',
            element.purchase_order_id
          );
          uploadData.append(
            'products[' + index + '][requested_delivery_date]',
            element.requested_delivery_date
          );
          uploadData.append('products[' + index + '][notes]', element.notes);
          console.log('element.attachments', element.attachments);
          if (Array.isArray(element.attachments)) {
            element.attachments?.forEach((element2: any, index2: any) => {
              uploadData.append(
                'products[' + index + '][attachments][' + index2 + ']',
                element2.id && element2.file_name
                  ? element2.file_name
                  : element2
              );
            });
          }
        });
      }
      if (url.toLowerCase().includes('salesRequest'.toLowerCase())) {
        console.log('data.products', data.products);
        data.products.forEach(function (element: any, index: any) {
          uploadData.append('products[' + index + '][id]', element.id);
          //  uploadData.append('products[' + index + '][product_id]', element.product_id);
          uploadData.append(
            'products[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append('products[' + index + '][uom_id]', element.uom_id);
          uploadData.append('products[' + index + '][cost]', element.cost);
          uploadData.append(
            'products[' + index + '][proposed_cost]',
            element.proposed_cost
          );
          // uploadData.append('products[' + index + '][purchase_order_id]', element.purchase_order_id);
          uploadData.append(
            'products[' + index + '][requested_delivery_date]',
            element.requested_delivery_date
          );
          uploadData.append('products[' + index + '][notes]', element.notes);
          element.attachments?.forEach((element2: any, index2: any) => {
            uploadData.append(
              'products[' + index + '][attachments][' + index2 + ']',
              element2
            );
          });
        });
      }
      if (url.toLowerCase().includes('salesOrders'.toLowerCase())) {
        data.products.forEach(function (element: any, index: any) {
          uploadData.append('products[' + index + '][id]', element.id);
          uploadData.append(
            'products[' + index + '][quantity]',
            element.quantity
          );
          uploadData.append('products[' + index + '][uom_id]', element.uom_id);
          uploadData.append('products[' + index + '][cost]', element.cost);
          uploadData.append(
            'products[' + index + '][warehouse_id]',
            element.warehouse_id ? element.warehouse_id : null
          );
          //  uploadData.append('products[' + index + '][inventory_available_quantity]', element.inventory_available_quantity);
          //  uploadData.append('products[' + index + '][fulfillment_quantity]', element.fulfillment_quantity);

          // uploadData.append(
          //   'products[' + index + '][requested_delivery_date]',
          //   element.requested_delivery_date
          // );
          uploadData.append('products[' + index + '][notes]', element.notes);
          element.attachments?.forEach((element2: any, index2: any) => {
            uploadData.append(
              'products[' + index + '][attachments][' + index2 + ']',
              element2
            );
          });
        });
      }
    }
    if (data.product_attachments?.length > 0) {
      // debugger
      //  console.log('url is ', url, data.product_attachments)
      for (let i = 0; i < data.product_attachments?.length; i++) {
        //  console.log('data.product_attachments[i]', data.product_attachments[i])
        uploadData.append(
          'product_attachments[' + i + ']',
          data.product_attachments[i]
        );
      }
      // data.product_attachments.forEach(function (element: any, index: any) {
      //  //  console.log('product index',element,index)
      //   uploadData.append('product_attachments[' + index + ']', element);
      // });
    }
    if (data.kit_attachments?.length > 0) {
      // debugger
      //  console.log('url is ', url, data.product_attachments)
      for (let i = 0; i < data.kit_attachments?.length; i++) {
        //  console.log('data.product_attachments[i]', data.product_attachments[i])
        uploadData.append('kit_attachments', data.kit_attachments[i]);
      }
      // data.product_attachments.forEach(function (element: any, index: any) {
      //  //  console.log('product index',element,index)
      //   uploadData.append('product_attachments[' + index + ']', element);
      // });
    }

    if (data.packings?.length > 0) {
      ////  console.log('url is ', url)

      // if(url.toLowerCase().includes('salesRequest'.toLowerCase())){
      data.packings.forEach(function (element: any, index: any) {
        for (var key in element) {
          ////  console.log('element',element,'item[key]',element[key],'key',key)
          if (element[key]) {
            ////  console.log('packings[' + index + ']['+key+']', element[key])
            uploadData.append(
              'packings[' + index + '][' + key + ']',
              element[key]
            );
          }
        }
        //  console.log('upload data', uploadData)
        // uploadData.append('packings[' + index + '][uom_name]', element.uom_name);
        // uploadData.append('packings[' + index + '][quantity]', element.quantity);
        // if (element.width) {
        //   uploadData.append('packings[' + index + '][width]', element.width);
        // }
        // if (element.height) {
        //   uploadData.append('packings[' + index + '][height]', element.height);
        // }
        // if (element.length) {
        //   uploadData.append('packings[' + index + '][length]', element.length);
        // }
        // if (element.weight) {
        //   uploadData.append('packings[' + index + '][weight]', element.weight);
        // }
      });
      // }
    }

    if (data.tags?.length > 0) {
      ////  console.log('url is ', url, data.tags)
      data.tags.forEach(function (element: any, index: any) {
        uploadData.append('tags[' + index + ']', element);
      });
    }

    if (data.tracking_type) {
      //  console.log('data.tracking', data.tracking_type)
      // debugger
      // let trackingArray = data.tracking_type.split(',')
      ////  console.log('url is ', url, data.tags)
      data.tracking_type.forEach(function (element: any, index: any) {
        //  console.log('type is', element, index)
        uploadData.append('tracking_type[' + index + ']', element);
      });
    }

    const baseServiceUrl = this.setBaseUrl(
      anotherService ? anotherService : this.accountTypeAPI
    );
    await this.http
      .post(baseServiceUrl + url, uploadData, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        result = error;
      });

    return result;
  }

  async postApi(url: any, params: any, headerson?: any, anotherService?: any) {
    let serviceURL = anotherService ? anotherService : this.accountTypeAPI;

    const baseServiceUrl = this.setBaseUrl(serviceURL);

    const options = this.headerSetup(
      'application/json; charset=utf-8; text/html; text/plain'
    );
    let result: any;
    // this.Interceptor.intercept()
    await this.http
      .post(baseServiceUrl + url, params, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });

    return result;
  }

  async putApi(url: any, params: any, headerson?: any, anotherService?: any) {
    const options = this.headerSetup('application/json; charset=utf-8');
    const baseServiceUrl = this.setBaseUrl(
      anotherService ? anotherService : this.accountTypeAPI
    );

    let result: any;
    await this.http
      .put(baseServiceUrl + url, params, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });

    return result;
  }

  async patchApi(url: any, params: any, headerson?: any) {
    const options = this.headerSetup('application/json');
    const baseServiceUrl = this.setBaseUrl(this.accountTypeAPI);
    let result: any;
    await this.http
      .patch(baseServiceUrl + url, params, options)
      ?.toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });
    return result;
  }

  async getApi(url: any, params?: any, headerson?: any, anotherService?: any) {
    let serviceURL = anotherService ? anotherService : this.accountTypeAPI;

    const options = {
      ...this.headerSetup('application/json'),
      params,
    };
    const baseServiceUrl = this.setBaseUrl(serviceURL);
    let result: any;
    await this.http
      .get(baseServiceUrl + url, options)
      ?.toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error?.error?.message === 'Authorization Token not found' ||
          error?.error?.message === 'Token is Expired'
        ) {
          this.openSnackBar(error?.error?.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });
    return result;
  }


  async getPdfApi(url: any, params?: any, headerson?: any, anotherService?: any) {
    let serviceURL = anotherService ? anotherService : this.accountTypeAPI;

    const options = {
      ...this.headerSetup("application/pdf"),
      params,
      responseType: "arraybuffer" as "json",
    };
    const baseServiceUrl = this.setBaseUrl(serviceURL);
    let result: any;
    await this.http
      .get(baseServiceUrl + url, options,)
      ?.toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });
    return result;
  }

  async postAttachmentApi(url: any, key: any, value?: any, service?: Services) {
    const options = this.headerSetup('multipart/form-data;');

    const baseServiceUrl = this.setBaseUrl(service ? service : this.accountTypeAPI);

    let result: any;

    const uploadData: FormData = new FormData();
    if (value) {
      uploadData.append(key, value);
    }

    await this.http
      .post(baseServiceUrl + url, uploadData, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });

    return result;
  }

  GUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  async deleteApi(
    url: any,
    params?: any,
    headerson?: any,
    anotherService?: any
  ) {
    const options = this.headerSetup('application/json');
    const baseServiceUrl = this.setBaseUrl(
      anotherService ? anotherService : this.accountTypeAPI
    );

    this.loading = true;
    let result: any;
    await this.http
      .delete(baseServiceUrl + url, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        if (
          error.error.message === 'Authorization Token not found' ||
          error.error.message === 'Token is Expired'
        ) {
          this.openSnackBar(error.error.message, 'close', 2000);
          this.router.navigate(['login']);
        }
        result = error;
      });
    this.loading = false;
    return result;
  }

  async postSettedFormData(url: any, data: any, headerson?: any) {
    const options = this.headerSetup('multipart/form-data;');

    const baseServiceUrl = this.setBaseUrl(this.accountTypeAPI);

    let result: any;

    await this.http
      .post(baseServiceUrl + url, data, options)
      .toPromise()
      .then(async (resp: any) => {
        result = await resp;
      })
      .catch((error) => {
        result = error;
      });

    return result;
  }

  downloadFile(exportUrl: any): any {
    return this.http.get(exportUrl, { responseType: 'blob' });
  }

  setProducts(prods: any) {
    this.selectedProductsAssign.next(prods);
  }

  setLocationArray(prods: any) {
    this.selectedLocationsArray.next(prods);
  }
}

export interface ErrorObject {
  description: string;
  fields: string[];
}

export interface LoginObj {
  username: any;
  password: any;
}

export interface DtoResult {
  errors: ErrorObject[] | string;
  isSuccessful: boolean;
  keyValue: any;
}
