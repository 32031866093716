import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  hide = true;
  isLoading = false;
  isRemember = false;
  currentDisplayDepartment!: number;
  devicetoken = '';
  token: any;
  rememberMe: any;
  isloggedin: boolean = false;
  submitted: boolean = false;

  constructor(
    private route: Router,
    public service: ConfigService,
    public router: Router,
    public snackbarService: CustomSnackbarService,
    private dialogRef: MatDialog,
    public alert: AlertService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
  }

  forgotForm = this.formBuilder.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],

    shop: [null],
    scope: [null],
    accessToken: [null],
  });

  async forgotPassword(event?: any) {

    this.submitted = true;
    if (
      this.forgotForm.controls['email'].invalid
    ) {
      this.alert.show('Please enter your Email!', 'error');
      return;
    }

    this.isLoading = true;
    try {
      const response: any = await this.service.postApi(
        '/api/users/send-password-mail',
        this.forgotForm.value,
        '', 'auth_service');
      console.log(response.message);
      if (response.status === 201) {
        this.alert.show(response.message, 'success');
        this.forgotForm.reset();
      } else {
        this.alert.show(response.message, 'error');
        this.forgotForm.reset();
      }
    } catch (error: Error | any) {
      this.alert.show(error?.error?.message, 'error');
      this.forgotForm.reset();
    }
  }

  async navigateToLogin() {
    this.route.navigate(['login']);
  }

  ngOnInit() {
  }

}
