import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  hide = true;
  isLoading = false;
  isRemember = false;
  currentDisplayDepartment!: number;
  devicetoken = '';
  token: any;
  rememberMe: any;
  isloggedin: boolean = false;
  submitted: boolean = false;


  constructor(
    private route: Router,
    public service: ConfigService,
    public router: Router,
    public snackbarService: CustomSnackbarService,
    private dialogRef: MatDialog,
    public alert: AlertService,
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute
  ) {
    this.dialogRef.closeAll(); // close all the popups on logout or route to main page
  }

  loginForm = this.formBuilder.group({
    email: [null, Validators.compose([Validators.required, Validators.email])],

    password: [null, Validators.required],
    shop: [null],
    scope: [null],
    accessToken: [null],
  })

  async getLogin(event?: any) {
    this.submitted = true;

    if (this.loginForm.controls['email'].invalid && this.loginForm.controls['password'].invalid) {
      this.alert.show('Please enter your Email & Password!', 'error');
      return;
    }

    if (this.loginForm.controls['email'].invalid) {
      this.alert.show('Email Is Required', 'error');
      return;
    }

    if (this.loginForm.controls['password'].invalid) {
      this.alert.show('Password Is Required', 'error');
      return;
    }
    this.isLoading = true;
    try {
      const response: any = await this.service.postApi('/api/auth/login', this.loginForm.value, '', 'auth_service');
      this.service.settoken(response.data.access_token);
      this.token = response.data.access_token;
      this.service.token = response.data.access_token;
      this.service.setuser(response.data);

      this.alert.show(response.message, 'success');

      // console.log(response.data);
      // console.log(JSON.stringify(response.data.permissions.formatted_permissions));
      // console.log(localStorage.getItem('permissions'));
      // return;

      localStorage.setItem('access_token', this.token);
      localStorage.setItem('email', this.loginForm.controls['email'].value);
      localStorage.setItem('password', this.loginForm.controls['password'].value);
      localStorage.setItem('rememberMe', this.rememberMe);
      if (this.rememberMe === true) {
        localStorage.setItem('rememberMe', "true")
      }
      if (this.rememberMe === false || this.rememberMe === undefined) {
        localStorage.setItem('rememberMe', "false")
      }
      if(response.data.account_type == "admin"){
        this.route.navigate(['account-management/company-account']);
      }else{
        this.route.navigate(['dashboard']);
      }
      this.submitted = false;
    } catch (response: any) {
      if (response.error?.message) {
        this.alert.show(response.error.message, 'error');
        this.submitted = true;
      } else {
        this.alert.show(response.message, 'error');
        this.submitted = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  AutoLogin() {
    const accessTokenObj = localStorage.getItem("access_token");
    const rememberMe = localStorage.getItem('rememberMe');
    if (accessTokenObj && rememberMe === "true") {
      this.isloggedin = true;
      this.rememberMe = true;
      localStorage.setItem('rememberMe', "true");
      this.alert.show('You are already logged in', 'success');
    }

    if (accessTokenObj && rememberMe === "false" || accessTokenObj && rememberMe === undefined) {
      this.isloggedin = false;
      this.rememberMe = false;
      localStorage.setItem('rememberMe', "false");
    }
  }

  gotodashboard() {
    if (this.isloggedin === true && this.rememberMe === "true" || this.rememberMe === true) {
      this.route.navigate(['Dashboard']);
    }
    else {
      this.getLogin();
    }
  }

  resetpassword() {
    this.route.navigate(['forgot-password']);
  }

  ngOnInit() {
    // Subscribe to query parameters observable
    this.activatedRoute.queryParams.subscribe(params => {
      // Access individual parameters
      const getState = params['state'] as string | undefined;
      if(getState){
        // Decoding getState from base64 encoding
        const base64_decode = decodeURIComponent(window?.atob(getState));
        // Decoding base64_decode from json encoding
        const json_decode = JSON.parse(base64_decode);
        // Access individual property of json_decode obj
        const shop = json_decode['shop'];
        const scope = json_decode['scope'];
        const accessToken = json_decode['accessToken'];
        // Setting values to hidden inputs respectively
        this.loginForm.controls['shop'].setValue(shop);
        this.loginForm.controls['scope'].setValue(scope);
        this.loginForm.controls['accessToken'].setValue(accessToken);
      }
    });
    this.AutoLogin();
  }
}
