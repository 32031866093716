
<div class="container-fluid">
    <div class="row justify-content-between border-bottom mb-3">
        <div class="col-9 pl-3">
            <h2 mat-dialog-title>Notes</h2>
        </div>

        <div class="col-3 pr-3 text-right">
            <mat-icon mat-dialog-close>clear</mat-icon>
        </div>
    </div>

    <mat-dialog-content class="mat-typography">
        <mat-form-field appearance="outline" class="w-100" *ngIf="data.detailmode">
            <textarea matInput [(ngModel)]="notes" placeholder="Notes" readonly rows="2"></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100" *ngIf="!data.detailmode">
            <textarea matInput [(ngModel)]="notes" placeholder="Notes" rows="2"></textarea>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">

        <div *ngIf="data.detailmode === true" class="w-100 px-2">
            <button mat-flat-button mat-dialog-close class="btn-main"> Close</button>
        </div>

        <div *ngIf="!data.detailmode === true" class="w-100 px-2">
            <button mat-flat-button mat-dialog-close class="btn-outlined">Close</button>
            <button mat-flat-button class="btn-main" (click)="sendnotes()"> Save</button>
        </div>
    </mat-dialog-actions>


</div>
