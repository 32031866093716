import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { CustomSnackbarService } from '../pages/shared/custom-snackbar/custom-snackbar.service';
import { AlertService } from '../pages/shared/alert/alert.service';
import { ConfigService } from './config.service';
import { Observable , from, of} from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuDataResolverService implements Resolve<any>{

  constructor(public service: ConfigService, private route: Router, public snackbarService: CustomSnackbarService, public alert: AlertService) { }
  resolve(): Observable<any> {
    return from(this.service.getApi('/api/auth/side-menu', {}, '', 'auth_service')).pipe(
      catchError(error => {
        // Handle errors here
        console.error(error);
        return of(null);
      })
    );
  }
}
