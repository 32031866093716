<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<!--   -->
<ng-container>
  <c-container [fluid]="true">
    <!-- <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button> -->
    <div class="header-search">
      <!-- <mat-icon matPrefix>search</mat-icon>
            <input matInput placeholder="Search..."> -->
    </div>
    <c-header-nav class="d-none d-md-flex d-lg-flex me-auto">
      <!-- <c-nav-item>
                <a cNavLink routerLink="/dashboard" routerLinkActive="active">
          Dashboard
        </a>
            </c-nav-item>
            <c-nav-item>
                <a cNavLink routerLink="/users" routerLinkActive="active">Users</a>
            </c-nav-item>
            <c-nav-item>
                <a cNavLink routerLink="/settings" routerLinkActive="active">
          Settings
        </a>
            </c-nav-item> -->
    </c-header-nav>

    <c-header-nav class="d-none d-lg-flex d-md-flex">
      <!-- <ng-select appearance="outline" (change)="getWarehouses()" (clear)="WarehouseSelect.handleClearClick()" placeholder="Select" appendTo="body"
      [searchable]="true" [multiple]="true" [closeOnSelect]="false" formControlName="client_ids"
      [items]="clientsList" [loading]="clientsLoader" bindLabel="client_name" bindValue="id"
      class="d-grid" [ngClass]="{
      'input-error':
        !WorkflowForm.controls['client_ids'].valid && submitted
    }">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }" /> {{item.client_name}}
      </ng-template>


      <ng-template ng-multi-label-tmp let-items="items" let-index="index">
        <div class="ng-value" *ngFor="let item of items; let ind = index">
          <span class="ng-value-label" *ngIf="ind < 2">{{ item?.client_name }}</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 2">
          <span class="ng-value-label">{{items.length - 2}} more...</span>
        </div>
      </ng-template>

    </ng-select> -->

      <ng-select
  *ngIf="this.service.accountTypeAPI == 'wms_service' && !isGlobalFilter"
  [(ngModel)]="selectedClients"
  appearance="outline"
  appendTo="body"
  placeholder="Select Client"
  [multiple]="true"
  [searchable]="true"
  [loading]="clientDDLoader"
  class="d-grid global-filter"
  (change)="onClientsChange($event)"
  [closeOnSelect]="false"
  [readonly]="!isClient"
  bindLabel="client_name"
   bindValue="id"
  [items]="clients"
>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="d-flex align-items-center">
    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
      [ngModelOptions]="{ standalone: true }" /> {{item.client_name}}
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-index="index">
    <div class="ng-value">
      <span class="ng-value-label" *ngIf="showAllClient">{{ 'All' }}</span>
    </div>
    <ng-container *ngIf="!showAllClient">
      <div class="ng-value d-flex" *ngFor="let item of items.slice(0,1); let ind = index">
        <span class="ng-value-label wrap-text">{{ item?.client_name }}</span>
      </div>
      <div class="ng-value" *ngIf="items.length > 1">
        <span class="ng-value-label">{{items.length - 1}} more...</span>
      </div>
    </ng-container>

  </ng-template>
</ng-select>
      <ng-select
        *ngIf="this.service.accountTypeAPI == 'wms_service' && !isGlobalFilter"
        [(ngModel)]="selectedWarehouse"
        appearance="outline"
        appendTo="body"
        placeholder="Select Warehouse"
        [searchable]="true"
        [multiple]="true"
        [loading]="warehouseDDLoader"
        class="d-grid global-filter"
        (change)="onWarehouseChange($event)"
        [closeOnSelect]="false"
        [readonly]="!isWarehouse"
        bindLabel="name"
        bindValue="id"
       [items]="warehouses"

      >
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
         <div class="d-flex align-items-center">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }" /> {{item.name}}
         </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items" let-index="index">
          <div class="ng-value">
            <span class="ng-value-label" *ngIf="showAllWarehouse">{{ 'All' }}</span>
          </div>
          <ng-container *ngIf="!showAllWarehouse">
            <div class="ng-value d-flex" *ngFor="let item of items.slice(0,1); let ind = index">
              <span class="ng-value-label wrap-text" >{{ item?.name }}</span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{items.length - 1}} more...</span>
            </div>
          </ng-container>

        </ng-template>
      </ng-select>
      <!-- <c-nav-item>
                <a cNavLink class="d-flex">
                    <i class="icon-notification"></i>
                </a>

            </c-nav-item>
            <c-nav-item>
                <a cNavLink class="d-flex">
                    <i class="icon-settings"></i>
                </a>
            </c-nav-item> -->
      <!-- user profile & dropdown -->
      <c-nav-item>
        <div style="margin-right: 10px">{{ client_name ?? "" }}</div>
        <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
      </c-nav-item>
    </c-header-nav>
  </c-container>
  <!-- <c-header-divider></c-header-divider>
    <c-container [fluid]="true">
        <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
    </c-container> -->
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button cButton color="" [caret]="false" cDropdownToggle class="profile">
      <img src="../../../../assets/img/avatars/profile-3.png" alt="Jane doe" />
    </button>
    <ul cDropdownMenu class="pt-0 pr-5 w-auto">
      <li *ngIf="isWizeGuide">
        <a  cDropdownItem (click)="startTour()">
          <svg cIcon class="me-2" name="cilLockLocked"></svg>   Wize Guide
        </a>
      </li>
      <li *ngIf="isUserProfile">
        <a routerLink="/account-management/user-account" cDropdownItem>
          <svg cIcon class="me-2" name="cil-user"></svg> My Profile
        </a>
      </li>
      <li *ngIf="isCompanyProfile">
        <a routerLink="./" (click)="companyProfile()" cDropdownItem>
          <svg cIcon class="me-2" name="cil-briefcase"></svg> Company Profile
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem (click)="logout()">
          <svg cIcon class="me-2" name="cil-account-logout"></svg> Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>
