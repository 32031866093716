import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

type AlertType = 'success' | 'error';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private snackbarSubject = new Subject<any>();
  public alertState = this.snackbarSubject.asObservable();

  constructor() { }

  show(message: string, type?: AlertType, heading?: string) {
    this.snackbarSubject.next({
      show: true,
      message,
      type,
      heading
    });
  }
}
