import { Component, OnInit } from '@angular/core';
import { Form } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/pages/shared/alert/alert.service';
import { CustomSnackbarService } from 'src/app/pages/shared/custom-snackbar/custom-snackbar.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  hide = true;
  msg: any;
  email : any = ''; //client@shtiks.com
  password : any = ''; //password
  data: any = {};
  reset_password:any;
  email_reset: boolean = false;
  phone_no_reset: boolean = false;
  reset_password_view: boolean = true;
  reset_password_view1: boolean = false;
  constructor(private route: Router, public service: ConfigService, public snackbarService:CustomSnackbarService, public alert: AlertService) {
  }

  async forgotpassword() {
    if (this.email == '') {
      this.alert.show('Email Is Required', 'error');
      return;
    }

    const data1: any = await this.service.postApi(`auth_service/api/auth/password/forgot?email=${this.email ? this.email : ''}`, "");
    if (data1.status === 200) {
      this.alert.show(data1.message, 'success');
      this.service.email = this.email;
      // this.route.navigate(['New-Password']);
    }
    else {
      if (data1.status === 422) {
        this.alert.show(data1.errors.email, 'error');
      }
    }
  }

  radiochange(e: MatRadioChange) {
    this.reset_password = e.value
  }

  changeview() {
    if(this.reset_password_view === true){
      this.reset_password_view1 = true;
      this.reset_password_view = false;
    }
    else{
      this.reset_password_view = true;
      this.reset_password_view1 = false;
    }

  }

  ngOnInit() {
  }

}
