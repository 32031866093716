import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ClassToggleService, HeaderComponent } from "@coreui/angular";
import { CustomSnackbarService } from "src/app/pages/shared/custom-snackbar/custom-snackbar.service";
import { ConfigService } from "src/app/services/config.service";
import { CommonService } from "src/app/services/comman.service";
import { JoyrideService } from "ngx-joyride";

@Component({
  selector: "app-default-header",
  templateUrl: "./default-header.component.html",
  styleUrls: ["./default-header.component.scss"],
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId: string = "sidebar";
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl("auto");
  options: FormGroup;
  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);
  profilepic: any = "./assets/img/avatars/8.jpg";
  service_provider: any;
  check: any;
  client_name: any;
  client_id: any;
  email: any;
  warehouses: any;
  warehouseDDLoader: any;
  selectedWarehouse: any = [];
  prevselectedWarehouse: any = [];
  clients: any;
  clientDDLoader: any;
  selectedClients: any = [];
  prevSelectedClients: any = [];
  isWizeGuide: Boolean = false;
  isUserProfile: Boolean = false;
  isCompanyProfile: Boolean = false;
  isGlobalFilter: Boolean = false;
  isClient: Boolean = true;
  isWarehouse: Boolean = true;
  showAllClient: boolean = false;
  prevshowAllClient: boolean = false;
  showAllWarehouse: boolean = false;
  accountId: any;
  
  constructor(
    public snackbarService: CustomSnackbarService,
    private classToggler: ClassToggleService,
    fb: FormBuilder,
    public service: ConfigService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private commanService: CommonService,
    private readonly joyrideService: JoyrideService
  ) {
    super();
    this.options = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
    });
    if (localStorage.getItem("warehouse_id")) {
      console.log("warehouse-id", localStorage.getItem("warehouse_id"));
    }
  }

  async logout() {
    await this.route.navigate(["login"]);
    this.service.clearFilterState();
    localStorage.removeItem("access_token");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("warehouse_id");
    localStorage.removeItem("client_id");
  }

  ngOnInit(): void {
    if (this.service.profileDetail?.account_type != "admin") {
      this.getClients();
      this.setWarehouse();
      this.getGlobalFilterShow();
      this.isWizeGuide = true;
      this.isUserProfile = true;
      if (this.service.profileDetail?.check_service_provider) {
        this.isCompanyProfile = true;
      }
      this.accountId = this.service?.profileDetail?.id;
      if (this.service.profileDetail.sub_client_id) {
        // this.selectedClient=this.service.profileDetail.sub_client_id
        this.isClient = false;
        // this.setWarehouse(this.selectedClient);
        this.onClientsChange([
          { id: this.service.profileDetail.sub_client_id },
        ]);
      }
      if (this.service.profileDetail.warehouse_id) {
        // this.selectedWarehouse=this.service.profileDetail.warehouse_id
        this.onWarehouseChange([
          { id: this.service.profileDetail.warehouse_id },
        ]);
        this.isWarehouse = false;
      }
    }

    this.email = localStorage.getItem("email");
    this.check = this.service.profileDetail?.account_type;
    this.client_name = this.service.profileDetail?.client_from;
    this.getLoggedInUserDefaultValues(this.email);
    this.getClients();
    if (this.service.profileDetail?.profilepic) {
      this.profilepic = this.service.profileDetail?.profilepic;
    }
  }

  async onWarehouseChange(event: any) {
    const Allwarehouse = this.warehouses.filter((item: any) => item.name !== "All");
    this.selectedWarehouse = event.map((item: any) => item.id);
    this.showAllWarehouse = event.some((item: any) => item.id == "all");
    const prevshowAllWarehouse = this.prevselectedWarehouse.some((item: any) => item == "all");

    if(this.showAllWarehouse && !prevshowAllWarehouse) 
    {
      if (this.selectedWarehouse.includes("all")) {
        this.selectedWarehouse = this.warehouses.map((item: any) => item.id);
      } else {
        this.selectedWarehouse = [];
      }
    } else if(this.showAllWarehouse && prevshowAllWarehouse){
      this.selectedWarehouse = this.selectedWarehouse.filter((id:any) => id !== 'all');
      this.showAllWarehouse = false;
    } else if(!this.showAllWarehouse && prevshowAllWarehouse){
      this.selectedWarehouse = [];
    } else if (!this.showAllWarehouse && !prevshowAllWarehouse && this.selectedWarehouse.length === Allwarehouse.length){
      this.showAllWarehouse = true;
      this.selectedWarehouse = this.warehouses.map((item: any) => item.id);
    }
    this.prevselectedWarehouse = this.selectedWarehouse;
    
    localStorage.setItem(
      "warehouse_id",
      JSON.stringify(this.selectedWarehouse)
    );
    this.commanService.setSelectedWarehouse(event);
    this.setPayload(this.selectedClients, this.selectedWarehouse);
  }

  async setPayload(client_ids: any[], warehouse_id: any[]) {
    let setTokenPayloadApi = "/api/set-payload";
    const data: any = await this.service.postApi(
      setTokenPayloadApi,
      { client_id: client_ids, warehouse_id: warehouse_id },
      {},
      "auth_service"
    );
    localStorage.setItem("access_token", data.access_token);
    this.commanService.sendUpdate(
      "Message from Sender Component to Receiver Component!"
    );
    this.setWarehouse(this.selectedClients);
  }

  async onClientsChange(event: any) {
    const Allclients = this.clients.filter((item: any) => item.client_name !== "All");
    this.selectedClients = event.map((item: any) => item.id);
    this.showAllClient = event.some((item: any) => item.client_name == "All");
    const prevshowAllClient = this.prevSelectedClients.some((item: any) => item == "all");  

    if(this.showAllClient && !prevshowAllClient) 
    {
      if (this.selectedClients.includes("all")) {
        this.selectedClients = this.clients.map((client: any) => client.id);
      } else {
        this.selectedWarehouse = [];
      }
    } else if(this.showAllClient && prevshowAllClient){
      this.selectedClients = this.selectedClients.filter((id:any) => id !== 'all');
      this.showAllClient = false;
    } else if(!this.showAllClient && prevshowAllClient){
      this.selectedClients = [];
    } 
    else if (!this.showAllClient && !prevshowAllClient && this.selectedClients.length === Allclients.length){
      this.showAllClient = true;
      this.selectedClients = this.clients.map((client: any) => client.id);
    }
    this.prevSelectedClients = this.selectedClients;
    
    localStorage.setItem("client_id", JSON.stringify(this.selectedClients));
    localStorage.removeItem("warehouse_id");
    this.setPayload(this.selectedClients, []);
    this.commanService.setSelectedClient(this.selectedClients);
  }

  async setWarehouse(event?: any) {
    this.warehouseDDLoader = true;
    let warehouseApi = `/api/warehouse/lookup?client_id=${
      event ? event : ""
    }&isGlobal=1`;
    const data1: any = await this.service.getApi(
      warehouseApi,
      {},
      {},
      "wms_service"
    );
    if (data1.status && data1.data) {
      this.warehouses = data1.data;
      this.commanService.setWarehouses(data1.data);
      console.log("selectedWarehouse");
      const storedWarehousetIds = localStorage.getItem("warehouse_id");
      const defaultWarehouse = this.service.profileDetail.default_warehouse;

      if (storedWarehousetIds && storedWarehousetIds !== "undefined") {
        try {
          this.selectedWarehouse = JSON.parse(storedWarehousetIds).map(
            (id: string) => (id === "all" ? "all" : parseInt(id, 10))
          );
        } catch (error) {
          this.selectedWarehouse = [];
        }
      } else if (defaultWarehouse) {
        this.selectedWarehouse = [parseInt(defaultWarehouse)];
      }
    }
    this.commanService.setSelectedWarehouse(this.selectedWarehouse);
    this.showAllWarehouse = this.selectedWarehouse.some(
      (item: any) => item == "all"
    );
    this.warehouseDDLoader = false;
  }

  async getClients() {
    this.clientDDLoader = true;
    let clientApi = "/api/client/lookup?isGlobal=1";
    const data1: any = await this.service.getApi(
      clientApi,
      {},
      {},
      "auth_service"
    );

    if (data1.status && data1.data) {
      this.clients = data1.data;
      this.commanService.setClients(data1.data);
      const storedClientIds = localStorage.getItem("client_id");
      const defaultClient = this.service.profileDetail.default_client;

      if (storedClientIds && storedClientIds !== "undefined") {
        try {
          this.selectedClients = JSON.parse(storedClientIds).map((id: string) =>
            id === "all" ? "all" : parseInt(id, 10)
          );
        } catch (error) {
          this.selectedClients = [];
        }
      }
      if (this.selectedClients.length) {
        this.commanService.setSelectedClient(this.selectedClients);
        this.setWarehouse(this.selectedClients);
        this.showAllClient = this.selectedClients.some(
          (item: any) => item == "all"
        );
      }
      if (defaultClient) {
        this.selectedClients = [parseInt(defaultClient)];
      }
    }
    this.clientDDLoader = false;
  }

  async getLoggedInUserDefaultValues(email: any) {
    let defaultApi = `/api/users/default?email=${email}`;
    const res: any = await this.service.getApi(
      defaultApi,
      {},
      {},
      "auth_service"
    );
    if (res.data?.default_client_id) {
      this.onClientsChange([res.data?.default_client_id]);
    }
    if (res.data?.default_warehouse_id) {
      this.onWarehouseChange(res.data?.default_warehouse_id);
    }
  }

  stepDone() {
    this.route.navigate(["inventory/inventory-adjustments/list"]);
  }

  startTour() {
    const options = {
      steps: [
        "step1@inventory/inventory-adjustments/list",
        "step2@inventory/inventory-adjustments/detail/352?step=2",
        "step3@/inventory/inventory-adjustments/warehouse-detail/88?step=3",
        "step4@/inventory/inventory-adjustments/location-detail/6192?step=4",
        "step5",
        "step6",
        "step7",
        "step8",
        "step9",
      ],
      startWith: "step1@inventory/inventory-adjustments/list",
      waitingTime: 1000,
      stepDefaultPosition: "top",
      themeColor: "#345632",
      showPrevButton: false,
      showEndButton: false,
      logsEnabled: false,
    };
    this.joyrideService.startTour(options).subscribe(
      (step) => {
        this.commanService.setStartQuide({ step: step.number });
      },
      (e) => {},
      () => {
        this.stepDone();
      }
    );
  }

  companyProfile() {
    this.route.navigate(["company-profile" + "/form"], {
      queryParams: {
        editcheck: true,
        companyProfile: true,
        id: this.accountId,
      },
    });
  }

  getGlobalFilterShow() {
    this.commanService
      .getGlobalFilters()
      .pipe()
      .subscribe((value) => {
        if (value?.isShow) {
          this.isGlobalFilter = true;
        } else {
          this.isGlobalFilter = false;
        }
      });
      console.log('setting global filters: ',this.isGlobalFilter);

  }
}
